import { IonLabel, IonSegment, IonSegmentButton } from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { IBuyOrder, ISellOrder, ITradeTransaction } from "../../types/trade";
import { backend_url } from "../../utils/be";
import { useSelector } from "react-redux";
import { selectUserState } from "../../redux/reducers/appStateReducer";
import { OrderBookContext } from "../../pages/NewTradePage";
import { RootState } from "../../redux/store";
import MyOrderItem from "./TradeItems/MyOrderItem";
import TransactionCard from "./TransactionCard";


const MyOrders = () => {
    const [showTransactions, setShowTransactions] = useState(false);
    const [myOrders, setMyOrders] = useState<(ISellOrder | IBuyOrder)[]>([]);
    const { address } = useSelector((state: RootState) => state.twinergy);
    const [transactions, setTransactions] = useState<ITradeTransaction[]>([]);
    const user = useSelector(selectUserState);
    const { orderBook: [orderBookData] } = useContext(OrderBookContext);

    useEffect(() => {
        let sells: ISellOrder[] =
            orderBookData?.sellOrders.allSellOrders.filter(
                (order) => order.seller?.toLowerCase() === address?.toLowerCase()
            ) || [];
        let buys: IBuyOrder[] =
            orderBookData?.buyOrders.allBuyOrders.filter(
                (order) => order.buyer?.toLowerCase() === address?.toLowerCase()
            ) || [];
        let sortedOrders = [...sells, ...buys].sort((a: any, b: any) => {
            return a.timestamp > b.timestamp ? 1 : -1;
        });
        setMyOrders(sortedOrders);
    }, [orderBookData]);

    function getTransactions() {
        if (transactions.length > 0) return;
        // get all transactions
        axios
            .get(`${backend_url}/user/transactions/${user?.id}`)
            .then((res) => {
                setTransactions(res.data?.data?.reverse());
            })
            .catch((err) => {
                console.log(err);
            });
    }
    useEffect(() => {
        getTransactions();
    }, [orderBookData]);

    return (
        <div className="col-span-3 h-full border-r border-gray-200 text-center py-2 flex flex-col flex-auto">
            <section>
                <div className="py-2 border-b">Matched Orders</div>
                <header className="grid grid-cols-3 ">
                    <div>Price (TRGY)</div>
                    <div>Volume (kWh)</div>
                    <div>Total</div>
                </header>
                <section className="h-[40vh] overflow-hidden">
                    {orderBookData?.transactions.map((values, index) => {

                        return (
                            <section
                                key={index}
                                className="grid grid-cols-3 text-sm text-gray-500"
                            >
                                <div
                                    className={
                                        values.maker === "sell"
                                            ? "text-yellow-600"
                                            : "text-primaryGreen"
                                    }
                                >
                                    {(values.total / values.energyAmount).toFixed(2)}
                                </div>
                                <div>{values.energyAmount}</div>
                                <div>{values.total.toFixed(2)}</div>
                            </section>
                        );
                    })}
                </section>
            </section>
            <div className="px-2 border-y">
                <IonSegment color={"dark"}>
                    <IonSegmentButton onClick={() => setShowTransactions(false)}>
                        <IonLabel className="capitalize">My Orders</IonLabel>
                    </IonSegmentButton>
                    <IonSegmentButton onClick={() => setShowTransactions(true)}>
                        <IonLabel className="capitalize">Transactions</IonLabel>
                    </IonSegmentButton>
                </IonSegment>
            </div>
            {!showTransactions ? (
                <section>
                    <header className="grid grid-cols-3 ">
                        <div>Price (TRGY)</div>
                        <div>Volume (kWh)</div>
                        <div>Total</div>
                    </header>
                    <section className="h-[40vh] overflow-hidden">
                        {myOrders?.map((values, index) => {
                            return (
                                <MyOrderItem key={index} order={values} />
                            );
                        })}
                    </section>
                </section>
            ) : (
                <section>
                    <header className="grid grid-cols-4 ">
                        <div>TRGY</div>
                        <div>kWh</div>
                        <div>Total</div>
                        <div>Time</div>
                    </header>
                    <section className="h-[40vh] ">
                        {transactions?.map((transaction, index) => {

                            return (

                                < TransactionCard key={index} item={transaction} />
                            );
                        })}
                    </section>
                </section>
            )}
        </div>
    );
};

export default MyOrders;
