import { initializeApp } from "firebase/app"
import { getAuth } from "firebase/auth"


const firebaseConfig = {
    apiKey: "AIzaSyAhP02910vYJol0M-D-T0_PEGB9r6FlBfk",
    authDomain: "twinergy-30a28.firebaseapp.com",
    projectId: "twinergy-30a28",
    storageBucket: "twinergy-30a28.appspot.com",
    messagingSenderId: "967786517401",
    appId: "1:967786517401:web:aa30b6f503e94ba216f908",
    measurementId: "G-WTEF1EZ1KD"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app)

export { auth, app } 