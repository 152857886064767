

export const languagesData = [
    "English",
    "Hindi",
    "Mandarin Chinese",
    "Spanish",
    "French",
    "Arabic",
    "Bengali",
    "Russian",
    "Portuguese",
    "Indonesian",
    "Urdu",
    "German",
    "Japanese",
    "Swahili",
    "Italian",
    "Korean",
    "Turkish",
    "Telugu",
    "Tamil",
    "Marathi",
    "Thai",
    "Vietnamese",
    "Polish",
    "Ukrainian",
    "Dutch",
    "Gujarati",
    "Persian (Farsi)",
    "Romanian",
    "Kannada",
    "Malayalam",
    "Greek",
].sort();

