import React from "react";
import Image from "react-bootstrap/Image";

export default function Footer() {
  return (
    <React.Fragment>
      <div className="flex items-center">
        <div>
          <Image
            src={require("./../../asserts/images/footer_flag.svg").default}
            style={{ height: "80px", }}
          />
        </div>
        <div className="ml-2 text-start">
          Twinergy has received funding from the European Union's Horizon 2020
          Reasearch and Innovation Programme under Grant Agreement No. 957736
        </div>
      </div>
    </React.Fragment>
  );
}
