import { ethers } from "ethers";
import { useEffect, useState } from "react";
import useConnector from "./useConnector";
import config from "../config";
import meter_reading from "../contracts/meter_readings.json";
// import { toast } from "react-toastify";
import useGasStation from "./useGasStation";
import { useSelector } from "react-redux";

const useMeters = () => {
  const { provider } = useConnector();
  const { address } = useSelector(state => state.twinergy);
  const [contract, setContract] = useState(null);
  const { meters } = useGasStation();
  const location = window.location;

  // Reading
  const [mainIMeterReading, setIMainMeterReading] = useState();
  const [mainOMeterReading, setOMainMeterReading] = useState();
  const [pvIMeterReading, setIPVMeterReading] = useState();
  const [pvOMeterReading, setOPVMeterReading] = useState();
  const [battMainMeterReading, setBattMainMeterReading] = useState();
  const [battITranMeterReading, setIBattTranMeterReading] = useState();
  const [battOTranMeterReading, setOBattTranMeterReading] = useState();
  // ball
  const [mainMeterBal, setMainmeterBal] = useState(0);
  const [pvMeterBal, setPVMeterBal] = useState(0);
  const [batMeterBal, setBatMeterBal] = useState(0);
  const [batMainMeterBal, setBatMainMeterBal] = useState(0);

  // Address
  const [mainMeterAddress, setMainMeterAdd] = useState("");
  const [pvMeterAddress, setPVMeterAdd] = useState("");
  const [batMainMeterAddress, setBatMainMeterAdd] = useState("");
  const [batMeterAddress, setBatMeterAdd] = useState("");

  // Fee
  const [mainMeterGasFee, setMainMeterGasFee] = useState(0);
  const [batMainMeterGasFee, setBatMainMeterGasFee] = useState(0);
  const [pvMeterGasFee, setPVMeterGasFee] = useState(0);
  const [batMeterGasFee, setBatMeterGasFee] = useState(0);

  useEffect(() => {
    if (provider) {
      const signer = provider.getSigner();
      setContract(
        new ethers.Contract(config.meter_reading, meter_reading, signer)
      );
    }
  }, [provider, location.pathname]);

  useEffect(() => {
    for (let value of meters) {
      if (value.dwelling_identifier === "Main meter") {
        setMainMeterAdd(value.wallet_address);
        contract?.counts(value.wallet_address).then(_data => {
          contract
            ?.readings(value.wallet_address, Number(_data._hex) - 1)
            .then(data => {
              setIMainMeterReading(data?.inputReading);
              setOMainMeterReading(data?.outputReading);
            });
        });
      }
      if (value.dwelling_identifier === "PV meter") {
        setPVMeterAdd(value.wallet_address);
        contract?.counts(value.wallet_address).then(_data => {
          contract
            ?.readings(value.wallet_address, Number(_data._hex) - 1)
            .then(data => {
              setIPVMeterReading(data?.inputReading);
              setOPVMeterReading(data?.outputReading);
            });
        });
      }
      if (value.dwelling_identifier === "Battery Main meter") {
        setBatMainMeterAdd(value.wallet_address);
        contract?.counts(value.wallet_address).then(_data => {
          contract
            ?.readings(value.wallet_address, Number(_data._hex) - 1)
            .then(data => { setBattMainMeterReading(data?.outputReading); });
        });
      }
      if (value.dwelling_identifier === "Battery Transact meter") {
        setBatMeterAdd(value.wallet_address);
        contract
          ?.transactionReadings(value.wallet_address)
          .then(data => {
            setIBattTranMeterReading((data?.inputReading / 10 ** 18) * 1000);
            setOBattTranMeterReading((data?.outputReading / 10 ** 18) * 1000);
          }).catch(err => {
            console.error(err)
          });
      }

    }
  }, [address, meters, contract, location.pathname]);

  useEffect(() => {
    for (let value of meters) {
      provider.getBalance(value.wallet_address).then(data => {
        if (value.dwelling_identifier === "Main meter") {
          setMainmeterBal(Number(data._hex) / 10 ** 18);
          if (value.gas_fee) {
            setMainMeterGasFee(value.gas_fee);
          } else {
            setMainMeterGasFee(0);
          }
        }
        if (value.dwelling_identifier === "PV meter") {
          setPVMeterBal(Number(data._hex) / 10 ** 18);
          if (value.gas_fee) {
            setPVMeterGasFee(value.gas_fee);
          } else {
            setPVMeterGasFee(0);
          }
        }
        if (value.dwelling_identifier === "Battery Main meter") {
          setBatMainMeterBal(Number(data._hex) / 10 ** 18);
          if (value.gas_fee) {
            setBatMainMeterGasFee(value.gas_fee);
          } else {
            setBatMainMeterGasFee(0);
          }
        }
        if (value.dwelling_identifier === "Battery Transact meter") {
          setBatMeterBal(Number(data._hex) / 10 ** 18);
          if (value.gas_fee) {
            setBatMeterGasFee(value.gas_fee);
          } else {
            setBatMeterGasFee(0);
          }
        }
      });
    }
  }, [meters, location.pathname]);

  return {
    mainMeterBal,
    pvMeterBal,
    batMeterBal,
    batMainMeterBal,
    mainIMeterReading,
    mainOMeterReading,
    pvIMeterReading,
    pvOMeterReading,
    battMainMeterReading,
    battITranMeterReading,
    battOTranMeterReading,
    mainMeterGasFee,
    pvMeterGasFee,
    batMainMeterGasFee,
    batMeterGasFee,
    mainMeterAddress,
    pvMeterAddress,
    batMainMeterAddress,
    batMeterAddress,
  };
};

export default useMeters;
