import React, { useEffect, useState } from 'react'
import { Routes, Route } from 'react-router-dom'
import AccountPage from '../pages/AccountPage'
import DERRewardsHistory from '../pages/DERRewardHistory'
import DERRewards from '../pages/DERRewards'
import ErrorPage from '../pages/ErrorPage'
import FaucetPage from '../pages/FaucetPage'
import MetersPage from '../pages/MetersPage'
import NewTradePage from '../pages/NewTradePage'
import ReadingsPage from '../pages/ReadingsPage'
import TradePage from '../pages/TradePage'
import TradeTransactions from '../pages/TradeTransactions'
import WalletPage from '../pages/WalletPage'
import WrongWalletPage from '../pages/WrongWalletPage'
import { useAuthAPI } from '../api/auth.api'
import { useSelector } from 'react-redux'
import { selectUserState } from '../redux/reducers/appStateReducer'

const UserRoutes = () => {
    const { getUserData } = useAuthAPI()
    const user = useSelector(selectUserState)
    const [userChecked, setUserChecked] = useState(false)


    useEffect(() => {
        if (user && !userChecked) {//if user is logged in and user data is not fetched
            getUserData(user.id + "", user?.token as string);
            setUserChecked(true)
        }//
    }, [])
    return (
        <Routes>
            <Route path="/" element={<NewTradePage />} />
            <Route path="/wallet" element={<WalletPage />} />
            <Route path="/faucet" element={<FaucetPage />} />
            <Route path="/der-rewards" element={<DERRewards />} />
            <Route path="/der-rewards/history" element={<DERRewardsHistory />} />
            <Route path="/readings" element={<ReadingsPage />} />
            <Route path="/account" element={<AccountPage />} />
            <Route path="/meters" element={<MetersPage />} />
            <Route path="/error" element={<ErrorPage />} />
            <Route path="/trade" element={<TradePage />} />
            <Route path="/new-trade" element={<NewTradePage />} />
            <Route path="/wrong-wallet" element={<WrongWalletPage />} />
            <Route path="/trade/transactions" element={<TradeTransactions />} />
        </Routes>
    )
}

export default UserRoutes