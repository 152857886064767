import React, { useState } from "react";
import { Button, Modal, Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import { IoClose, IoImageOutline, IoToggle } from "react-icons/io5";
import styles from "../layout/nav.module.css";
import { IonSpinner, IonToggle, useIonAlert, useIonToast } from "@ionic/react";
import axios from "axios";
import { der_baseurl } from "../../pages/DERRewards";
import { useSelector } from "react-redux";
import { selectUserState } from "../../redux/reducers/appStateReducer";
import { DRRReward, DRRToken } from "../../types/rewards";
import useDRR from "../../hooks/useDRR";
import useEGYVault from "../../hooks/useEGYVault";
import { RootState } from "../../redux/store";
import { toast } from "react-toastify";

interface ModalProps {
  show: boolean;
  reward: DRRReward;
  tokens: DRRToken;
  handleClose: () => void;
}

const ClaimRewardModal: React.FC<ModalProps> = ({
  show,
  handleClose,
  reward,
  tokens,
}) => {
  const { DRRBalance } = useSelector((state: RootState) => state.twinergy);
  const [approved, setApproved] = useState(false);
  const user = useSelector(selectUserState);
  const [loading, setLoading] = useState(false);
  const drr = useDRR();
  const { burnDRR } = useEGYVault();

  const handleClaimApprove = async () => {
    if (+reward.price > +DRRBalance) {
      handleClose()
      return toast.error("You don't have enough DRR to Claim this reward",);
    }
    if (loading) return;
    setLoading(true);
    let _approved = await drr.approve(reward.price);
    if (_approved) setApproved(true);
    setLoading(false);
  };

  const handleClaim = async () => {
    if (loading) return;
    const headers = {
      "ngrok-skip-browser-warning": true,
    };

    if (user?.id && +reward.price <= +DRRBalance) {
      setLoading(true);
      let result = await burnDRR(Number(reward.price));

      if (result) {
        await axios
          .post(
            `${der_baseurl}/claim_reward/${reward.id}`,
            { name: user.name, email: user.email },
            { headers }
          )
          .then((res) => {
            console.log(res.data);
            if (res.data.success) {
              toast.success("Successful claimed reward");
              setTimeout(() => {
                window.location.reload();
              }, 2000)
              handleClose();

            }
          })
          .catch((err) => {
            toast.error("Error claiming reward");
            handleClose()
          });
      } else {
        toast.error("Error claiming reward");
        handleClose();

      }
      setLoading(false);

    }
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      className="modal"
      style={{ borderRadius: "10px" }}
    >
      <Modal.Header style={{ position: "relative" }}>
        <Modal.Title>
          {approved ? "Claim Reward" : "Approve the claim"}
        </Modal.Title>
        <div onClick={handleClose}>
          <IoClose onClick={handleClose} />
        </div>
      </Modal.Header>
      <Modal.Body>

        {approved ?
          <p>
            Once you've clicked the claim reward button below, your details will
            be processed and the reward will be sent out to you as soon as
            possible via either post or email.
          </p>
          : <p>
            You are one step away from claiming the {reward.title} reward. This
            reward will cost you {reward.price} DRR tokens.
          </p>
        }
      </Modal.Body>
      <Modal.Footer>
        <Button
          className={styles.sbtn}
          style={{
            backgroundColor: "#7EDD62",
            borderRadius: "10px",
            border: "none",
            width: "100%",
          }}
          onClick={() => !approved ? handleClaimApprove() : handleClaim()}
        >
          {loading ? <IonSpinner /> : approved ? "Claim reward" : "Approve Claim"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ClaimRewardModal;
