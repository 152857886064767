/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { IonSegment, IonSegmentButton, IonCheckbox } from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import { IoInformationCircleOutline } from "react-icons/io5";
import useOrderBook from "../../hooks/useOrderBook";
import useTRGY from "../../hooks/useTRGY";
import config from "../../config";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { OrderBookContext } from "../../pages/NewTradePage";

const BuyEnergyForm = () => {
  const { address } = useSelector((state: any) => state.twinergy);
  const { price: [mkPrice, setMkPrice] } = useContext(OrderBookContext)
  const { createBuyOrder, fee } = useOrderBook();
  const [tabs, setTabs] = useState("0");
  const [loading, setLoading] = useState(false);
  const [approved, setApproved] = useState(false);
  const [price, setPrice] = useState<number>(0);
  const [actualFee, setActualFee] = useState<number>(0);
  const [totalPrice, setTotalPrice] = useState<number>(0);
  const [energyAmount, setEnergyAmount] = useState<number>(0);
  const [status, setStatus] = useState<string>("Approving...");
  const [partFill, setPartFill] = useState<boolean>(false);
  const { approve } = useTRGY();


  useEffect(() => {
    let _price = price;
    if (tabs === "0") _price = mkPrice;
    setTotalPrice(_price * energyAmount);
  }, [energyAmount]);

  useEffect(() => {
    if (partFill) {
      setActualFee(+fee * 5);
    } else {
      setActualFee(fee);
    }
  }, [partFill, fee]);

  useEffect(() => {
    if (loading) {
      if (!approved) {
        setStatus("Approving ...");
      } else {
        setStatus("Confirming ...");
      }
    }
  }, [approved, loading]);



  const handleApprove = async () => {
    if (!address) {
      toast.error("Please connect your wallet");
      return;
    }

    if (!energyAmount) return;
    setLoading(true);
    let res = await approve(totalPrice + actualFee, config.order_book);
    if (res) {
      setApproved(true);
      setTimeout(async () => {
        await buyWatt();
        setLoading(false);
      }, 2000);
    } else {
      setApproved(false);
      setLoading(false);
    }
  };

  const buyWatt = async () => {
    if (!energyAmount) return;
    const _price = tabs === "0" ? mkPrice : price;
    const result = await createBuyOrder(
      energyAmount,
      totalPrice + actualFee,
      partFill,
      _price
    );
    if (result) {
      toast.success("Buy order created successfully!");
      setEnergyAmount(0);
    } else {
      toast.error("Error occured while creating buy order");
    }
    setApproved(false);
  };

  return (
    <div>
      <form action="" className="p-3 absolute bottom-10 w-100">
        <div className="mb-4 ">
          <IonSegment
            value={tabs}
            onIonChange={(e) => {
              setTabs(e.detail.value + "");
            }}
            color="success"
          >
            <IonSegmentButton value={"0"}>Market</IonSegmentButton>
            <IonSegmentButton value={"1"}>Limit</IonSegmentButton>
          </IonSegment>
        </div>
        <div className="mb-2">
          <label>Price ( TRGY )</label>
          <input
            disabled={tabs === "0"}
            value={tabs === "0" ? mkPrice : price == 0 ? "" : price}
            onChange={(e) => setPrice(+e.target.value)}
            type="number"
            className={`p-2  border boder-black w-100 rounded outline-primaryGreen ${tabs === "0" ? "bg-gray-300" : "bg-gray-50"
              }`}
          />
        </div>
        <div className="mb-2">
          <label>Amount ( kWh )</label>
          <input
            type="number"
            value={energyAmount === 0 ? "" : energyAmount}
            onChange={(e) => setEnergyAmount(+e.target.value)}
            className="p-2 bg-gray-50 border boder-black w-100 rounded outline-primaryGreen"
          />
        </div>
        <div className="h-1"></div>
        <section>
          <div className="mb-2 flex mt-2" title="Description for part filling">
            <IonCheckbox
              color={"success"}
              checked={partFill}
              onIonChange={() => setPartFill(!partFill)}
              className="mr-2"
              mode="md"
            />{" "}
            <label
              onClick={() => setPartFill(!partFill)}
              className="cursor-pointer"
            >
              Allow part filling
            </label>
            <IoInformationCircleOutline className="text-green-500 ml-2 mt-[1px]" />
          </div>
        </section>
        <section className="mt-9 mb-3 text-end">
          <div className="text-gray-500">
            <small> Fee (including network charges)</small> {actualFee} TRGY
          </div>
          <div className="text-gray-800 font-semibold mt-2 ">
            Total {totalPrice + actualFee} TRGY
          </div>
        </section>
        <section>
          <button
            type="button"
            onClick={handleApprove}
            className="p-2 px-4 bg-primaryGreen w-100 text-white rounded"
          >
            {loading ? status : "Buy WATT"}
          </button>
        </section>
      </form>
    </div>
  );
};

export default BuyEnergyForm;
