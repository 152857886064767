import React, { useState } from "react";
import { Row, Col, Card, Form, Button, Image } from "react-bootstrap";
import "./../styles/walletpage.css";
import useTRGYVault from "../hooks/useTRGYVault";
import { useSelector } from "react-redux";
import useFTM from "../hooks/useFTM";
import useTRGY from "../hooks/useTRGY";
import styles from "./../styles/wallet.module.css";
import { FaArrowDown } from "react-icons/fa"
import { RootState } from "../redux/store";
import config from "../config";
import { motion } from "framer-motion";


export default function WalletPage() {
  const { WATTBalance, FTMBalance, DRRBalance, TRGYBalance, address } =
    useSelector((state: RootState) => state.twinergy);
  const { mint, withdraw } = useTRGYVault();
  const ftm = useFTM();
  const trgy = useTRGY();
  const [amount, setAmount] = useState<number | undefined>();
  const [approved, setApproved] = useState(false);
  const [loading, setLoading] = useState(false);
  const [timage, setTimage] = useState("sym.svg");
  const [fimage, setFimage] = useState("sym3.svg");
  const [tname, setTname] = useState("TRGY");
  const [fname, setFname] = useState("FTM");
  const [toFTM, setToFTM] = useState(false);
  const handleMouseEnter = (e: any) => {
    e.target.style.color = "#06152b";
  };
  const handleMouseLeave = (e: any) => {
    e.target.style.color = "white";
  };
  const approveAnimation = {
    approved: {
      background: "#FFBA4D", x: [0, 20, -20, 20, -20, 0, 20, -20, 20, -20, 0],
    },
    unapproved: {
      background: "#7edd62", x: 0
    }
  }
  const toggleSwap = () => {
    setToFTM(!toFTM);
    let temp1 = timage;
    setTimage(fimage);
    setFimage(temp1);
    let temp2 = tname;
    setTname(fname);
    setFname(temp2);
  };

  const handleMintApprove = async () => {
    if (loading) return;
    setLoading(true);
    let _approved = await ftm.approve(amount);
    if (_approved) setApproved(true);
    setLoading(false);
  };

  const handleMint = async () => {
    if (loading) return;
    setLoading(true);
    await mint(amount);
    setLoading(false);
    setApproved(false);
  };

  const handleWithdrawApprove = async () => {
    if (loading) return;
    setLoading(true);
    let _approved = await trgy.approve(amount, config.trgy_vault);
    if (_approved) setApproved(true);
    setLoading(false);
  };

  const handleWithdraw = async () => {
    if (loading) return;
    setLoading(true);
    await withdraw(amount);
    setLoading(false);
    setApproved(false);
  };

  return (
    <Row className="main-container">
      <Col >
        <Row style={{ padding: 30 }}>
          <div className="grid lg:grid-cols-2 gap-7">
            <div className="wallet-card">
              <Card.Body>
                <Card.Title className={styles.card_title}>
                  TRGY
                  <a
                    href={`https://testnet.ftmscan.com/address/${address}`}
                    target="_blank"
                  >
                    <Image
                      src={require("./../asserts/icons/arrowicon.svg").default}
                      className="icon"
                    />
                  </a>
                </Card.Title>
                <Card.Subtitle className={styles.card_subtitle}>
                  TwinERGY token
                </Card.Subtitle>
                <hr />
                <div className={styles.disp}>
                  <Image
                    src={require("./../asserts/icons/sym.svg").default}
                    className={styles.dispicon}
                  />
                  <div className={styles.dispval}>{TRGYBalance}</div>
                </div>
              </Card.Body>
            </div>

            <div className="wallet-card">
              <Card.Body>
                <Card.Title className={styles.card_title}>
                  WATT
                  <a
                    href={`https://testnet.ftmscan.com/address/${address}`}
                    target="_blank"
                  >
                    <Image
                      src={require("./../asserts/icons/arrowicon.svg").default}
                      className="icon"
                    />
                  </a>
                </Card.Title>
                <Card.Subtitle className={styles.card_subtitle}>
                  KWh token
                </Card.Subtitle>
                <hr />
                <div className={styles.disp}>
                  <Image
                    src={require("./../asserts/icons/sym2.svg").default}
                    className={styles.dispicon}
                  />
                  <div className={styles.dispval}>{WATTBalance}</div>
                </div>
              </Card.Body>
            </div>
            <div className="wallet-card">
              <Card.Body>
                <Card.Title className={styles.card_title}>
                  DRR
                  <a
                    href={`https://testnet.ftmscan.com/address/${address}`}
                    target="_blank"
                  >
                    <Image
                      src={require("./../asserts/icons/arrowicon.svg").default}
                      className="icon"
                    />
                  </a>
                </Card.Title>
                <Card.Subtitle className={styles.card_subtitle}>
                  Demand response reward token
                </Card.Subtitle>
                <hr />
                <div className={styles.disp}>
                  <Image
                    src={require("./../asserts/icons/sym1.svg").default}
                    className={styles.dispicon}
                  />
                  <div className={styles.dispval}>{DRRBalance}</div>
                </div>
              </Card.Body>
            </div>
            <div className="wallet-card">
              <Card.Body>
                <Card.Title className={styles.card_title}>
                  FTM{" "}
                  <a
                    href={`https://testnet.ftmscan.com/address/${address}`}
                    target="_blank"
                  >
                    <Image
                      src={require("./../asserts/icons/arrowicon.svg").default}
                      className="icon"
                    />
                  </a>
                </Card.Title>
                <Card.Subtitle className={styles.card_subtitle}>
                  Fantom token
                </Card.Subtitle>
                <hr />
                <div className={styles.disp}>
                  <Image
                    src={require("./../asserts/icons/sym3.svg").default}
                    className={styles.dispicon}
                  />
                  <div className={styles.dispval}>{FTMBalance}</div>
                </div>
              </Card.Body>
            </div>
          </div>
        </Row>
        <div className="lg:grid grid-cols-2 " style={{ padding: 30 }}>
          <div className="wallet-card">
            <Card.Body>
              <Card.Title className={styles.card_title}>Swap</Card.Title>
              <Card.Subtitle className={styles.card_subtitle}>
                Instantly swap {fname} for {tname}
              </Card.Subtitle>
              <hr />
              <Form>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <div
                    style={{ position: "relative" }}
                    className={styles.swapdiv}
                  >
                    <span className={styles.swap_text}>From</span>
                    <span
                      className={styles.swap_text}
                      style={{
                        position: "absolute",
                        right: "0px",
                        top: "0px",
                        zIndex: "1",
                      }}
                    >
                      Balance: <span>{toFTM ? TRGYBalance : FTMBalance}</span>
                    </span>
                  </div>
                  <div className={styles.inContainer}>
                    <span className={styles.inhov}>
                      <Image
                        src={require("./../asserts/icons/" + fimage)}
                        className={styles.dispicon}
                      />
                      {fname}
                    </span>
                    <input
                      type="text"
                      value={amount}
                      onChange={e => setAmount(+e.target.value)}
                      className={styles.text_in}
                    />
                  </div>
                  <Form.Text className="text-muted"></Form.Text>
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <div
                    style={{ position: "relative", width: "100%" }}
                    className={styles.swapdiv}
                  >
                    <span className={styles.swap_text}>To</span>{" "}
                    <span>
                      <FaArrowDown
                        style={{
                          position: "absolute",
                          top: 0,
                          cursor: "pointer",
                          left: "50%",
                          right: "50%",
                          zIndex: "1",
                        }}
                        onClick={() => toggleSwap()}
                      />
                      Balance: <span>{toFTM ? FTMBalance : TRGYBalance}</span>
                    </span>
                  </div>
                  <div className={styles.inContainer}>
                    <span className={styles.inhov}>
                      <Image
                        src={require("./../asserts/icons/" + timage)}
                        className={styles.dispicon}
                      />
                      {tname}
                    </span>
                    <input
                      type="text"
                      value={amount}
                      className={styles.text_in}
                      onChange={e => setAmount(+e.target.value)}
                    />
                    <div
                      style={{
                        position: "relative",
                        width: "100%",
                      }}
                      className={styles.swapdiv}
                    >
                      <span
                        className={styles.swap_text}
                        style={{
                          position: "absolute",
                          right: "0px",
                          top: "0px",
                          zIndex: "1",
                        }}
                      >
                        1 {fname} per {tname}
                        <span
                          onClick={() => toggleSwap()}
                          style={{ cursor: "pointer" }}
                        >
                          <Image
                            src={require("./../asserts/icons/flip.svg").default}
                          />
                        </span>
                      </span>
                    </div>
                  </div>
                  <Form.Text className="text-muted"></Form.Text>
                </Form.Group>
                <motion.button
                  initial={"unapproved"}
                  animate={approved ? "approved" : "unapproved"}
                  variants={approveAnimation}
                  style={{
                    borderRadius: "10px",
                    padding: "10px, 8px, 10px, 8px,",
                    marginTop: "20px",
                    border: "none",

                  }}
                  className="p-2 text-white w-full"
                  type="button"
                  onClick={() => {
                    if (toFTM) {
                      if (!approved) {
                        handleWithdrawApprove();
                      } else {
                        handleWithdraw();
                      }
                    } else {
                      if (!approved) {
                        handleMintApprove();
                      } else {
                        handleMint();
                      }
                    }
                  }}
                >
                  {loading ? (
                    "Loading..."
                  ) : (
                    <span>{approved ? "Confirm" : "Approve"}</span>
                  )}
                </motion.button>
              </Form>
            </Card.Body>
          </div>
        </div>
      </Col >
    </Row >
  );
}
