import React, { useState } from 'react'
import { AuthService } from '../../api/auth.api';
import { IonSpinner, useIonToast } from '@ionic/react';
import { setUserState } from '../../redux/reducers/appStateReducer';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const GoogleAuthButton = () => {
    const [loading, setLoading] = useState(false)
    const [toast] = useIonToast()
    const dispatch = useDispatch();
    const navigate = useNavigate()

    async function signinWithGoogle() {
        setLoading(true)
        await AuthService.googleSignin().then((res) => {
            if (res.data.success) {
                if (res.data.fa_auth) {
                    navigate("/verify-otp", { replace: true, state: { email: res.data.email } })
                    return;
                }
                localStorage.setItem("token", res.data.token)

                dispatch(setUserState({
                    country: "",
                    dwelling_id: "",
                    email: res.data.email,
                    id: res.data.id,
                    name: res.data.name,
                    token: res.data.token,
                    family_name: "",
                    iat: "",
                    id_number: "",
                    identificationNumber: "",
                    language: "en",
                    phone: "",
                    pilotId: "",
                    postal_address: "",
                    postalAddress: "",
                }))
                localStorage.setItem("user_logged_in", "true");
                window.location.href = "/";
            } else {
                toast({ message: res.data.message, buttons: [{ text: "Ok" }], color: "danger", position: "bottom" });
            }
        }).catch(err => {
            console.log(err);
        });
        setLoading(false)
    }
    return (
        <button name='google sign in button' onClick={signinWithGoogle} className="p-2 w-full border rounded-xl flex items-center text-black gap-x-5 hover:bg-gray-50">
            {loading ? <IonSpinner /> : <img src={"/images/logos/google.png"} className="w-8" alt="sign in with google" />} <span>Sign in with Google</span>
        </button>
    )
}

export default GoogleAuthButton