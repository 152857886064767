import React from 'react'
import useFTM from "./useFTM";
import useTRGY from "./useTRGY";
import useWATT from "./useWATT";
import useDRR from "./useDRR";
import useTRGYVault from './useTRGYVault';

const useData = () => {
  const drr = useDRR()
  const ftm = useFTM()
  const trgy = useTRGY()
  const watt = useWATT()
  const trgData = useTRGYVault()

  return {}
}

export default useData
