/* eslint-disable jsx-a11y/iframe-has-title */
import React, { createContext, useEffect, useState } from "react";
import MarketStats from "../components/TradeEnergy/MarketStats";
import MyOrders from "../components/TradeEnergy/MyOrders";
import TradeLemGraph from "../components/TradeEnergy/TradeLemGraph";
import BuyEnergyForm from "../components/TradeEnergy/BuyEnergyForm";
import SellEnergyForm from "../components/TradeEnergy/SellEnergyForm";
import { IOrderBook } from "../types/trade";
import { io } from "socket.io-client";
import { useSelector } from "react-redux";
import axios from "axios";
import { backend_url } from "../utils/be";
import redstone from "redstone-api";
import { IonButton, IonContent, IonHeader, IonInput, IonItem, IonLabel, IonModal, IonTitle, IonToolbar } from "@ionic/react";
import ProfileCompletion from "../components/modals/ProfileCompletion";
import { selectUserState } from "../redux/reducers/appStateReducer";


export const OrderBookContext = createContext<
  {
    orderBook: [IOrderBook | undefined,
      React.Dispatch<React.SetStateAction<IOrderBook | undefined>> | undefined],
    price: [number,
      React.Dispatch<React.SetStateAction<number>> | undefined]
  }
>({
  orderBook: [undefined, undefined],
  price: [0, undefined]
});


const socketURL = "https://socket.dev.twinergy.transactiveenergymodule.com"


const NewTradePage = () => {
  const [orderBookData, setOrderBookData] = useState<IOrderBook>();
  const [pricePerKw, setpricePerKw] = useState<number>(0);
  const { lemPrice, address } = useSelector((state: any) => state.twinergy)
  const user = useSelector(selectUserState)
  const [completeProfile, setCompleteProfile] = useState(!user?.country)



  useEffect(() => {
    if (address && !orderBookData) {
      const socket = io(socketURL, {
        extraHeaders: {
          "ngrok-skip-browser-warning": true as any,
        }
      });
      socket.on("connect", () => console.log("Socket Connected!"));
      socket.on("connect_error", () => {
        setTimeout(() => socket.connect(), 5000);
      });
      socket.on("data", (data: any) => {
        // console.log(JSON.parse(data))
        setOrderBookData(JSON.parse(data));
      });
      socket.on("disconnect", () => console.log("Socket Disconnected!"));
      return () => {
        socket.disconnect();
      };
    }
  }, [address]);

  useEffect(() => {
    if (redstone && lemPrice) {
      calculatePrice();
    }
  }, [redstone, lemPrice]);

  const getSettings = async () => {
    const result = await axios.get(`${backend_url}/user/trade/settings`);
    return result.data[0];
  }

  const calculatePrice = async () => {
    // Get rates from API Layer
    let { rate } = await getSettings();

    // price from red stone
    const priceFTMUSD = await redstone.getPrice("FTM");
    let FTMEUR = rate * priceFTMUSD.value;
    setpricePerKw(+lemPrice.bristol / FTMEUR)
  };


  return (
    <OrderBookContext.Provider value={{ orderBook: [orderBookData, setOrderBookData], price: [pricePerKw, setpricePerKw] }}>
      {!address && <div className="p-1 bg-yellow-500 text-center font-bold">
        Please Connect Wallet !
      </div>}
      <div className="">
        <section className="grid grid-cols-10 xl:grid-cols-12 h-full text-sm">
          <div className="cols-span-1 hidden xl:block border-r h-full"></div>
          <div className="col-span-2 h-[90vh]  border-gray-200 ">
            <MarketStats />
          </div>
          <div className="col-span-6 border-r border-gray-200 h-[900px] w-100">
            <section className="h-full grid grid-rows-2 w-100">
              <aside className="bg-gray-50">
                <TradeLemGraph />
              </aside>
              <aside className="grid grid-cols-2 w-100">
                <div className="relative">
                  <BuyEnergyForm />
                </div>
                <div className="border-l  relative">
                  <SellEnergyForm />
                </div>
              </aside>
            </section>
          </div>
          <div className="col-span-2  h-[90vh]">
            <MyOrders />
          </div>
          <div className="cols-span-1 hidden xl:block border-l h-full"></div>
        </section>
      </div>
      <ProfileCompletion isOpen={completeProfile} onDidDismiss={() => setCompleteProfile(false)} />
    </OrderBookContext.Provider>
  );
};

export default NewTradePage;


