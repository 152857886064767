import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface LemPriceData {
  bristol: string;
  hagedorn: string;
  benetutti: string;
}
interface TwinergyState {
  address: string;
  WATTBalance: string;
  FTMBalance: string;
  DRRBalance: string;
  TRGYBalance: string;
  lemPrice: LemPriceData;
}

const initialState: TwinergyState = {
  address: "",
  WATTBalance: "",
  FTMBalance: "",
  DRRBalance: "",
  TRGYBalance: "",
  lemPrice: {
    bristol: "",
    hagedorn: "",
    benetutti: ""
}
};

export const twinergyReducer = createSlice({
  name: "TwinERGY",
  initialState,
  reducers: {
    updateAddress: (state, action: PayloadAction<string>) => {
      state.address = action.payload;
    },

    updateWATTBal: (state, action: PayloadAction<string>) => {
      state.WATTBalance = action.payload;
    },

    updateFTMBal: (state, action: PayloadAction<string>) => {
      state.FTMBalance = action.payload;
    },

    updateDRRBal: (state, action: PayloadAction<string>) => {
      state.DRRBalance = action.payload;
    },

    updateTRGYBal: (state, action: PayloadAction<string>) => {
      state.TRGYBalance = action.payload;
    },
    updateLemPrice: (state, action: PayloadAction<any>) => {
      state.lemPrice.bristol = action.payload.bristol;
      state.lemPrice.hagedorn = action.payload.hagedorn;
      state.lemPrice.benetutti = action.payload.benetutti;
    }
  },
});

// Action creators are generated for each case reducer function
export const {
  updateAddress,
  updateWATTBal,
  updateFTMBal,
  updateDRRBal,
  updateTRGYBal,
  updateLemPrice,
} = twinergyReducer.actions;

export default twinergyReducer.reducer;