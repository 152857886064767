import React, { useState } from 'react'
import { DRRReward, DRRToken } from '../../types/rewards'
import { IoGiftOutline } from 'react-icons/io5'
import ClaimRewardModal from '../modals/ConfirmClaim'

const ClaimRewardCard: React.FC<{ reward: DRRReward, tokens?: DRRToken }> = ({ reward, tokens }) => {
    const [claimReward, setClaimReward] = useState(false)

    return (
        <>
            <div className="shadow-md rounded-2xl">
                <img className='w-100 h-[20vh] object-cover rounded-t-3xl' src={reward.image as string} alt="reward image" />
                <div className="p-4">
                    <div className='flex justify-between text-xl'>
                        <label className='font-bold'>{reward.title}</label>
                        <button className='flex items-center' name='reward price button' >
                            <IoGiftOutline className='inline-block mr-1 text-primaryGreen' />
                            <span className='font-bold'>{reward.price}</span>
                        </button>
                    </div>
                    <p className='mt-2 text-sm text-gray-500'>{reward.description}</p>
                    <div className="py-2">
                        {tokens && <button onClick={() => { setClaimReward(true) }} name='claim reward button'
                            className="w-100 rounded-md bg-primaryGreen text-white h-10">Claim</button>}
                    </div>
                </div>
            </div >
            {tokens && <ClaimRewardModal tokens={tokens} reward={reward} handleClose={() => { setClaimReward(false) }} show={claimReward} />
            }

        </>
    )
}

export default ClaimRewardCard