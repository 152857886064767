import { IonModal, IonHeader, IonToolbar, IonTitle, IonContent, IonItem, IonLabel, IonInput, IonButton, IonSelect, IonSelectOption, IonSpinner } from '@ionic/react'
import axios from 'axios';
import { id } from 'ethers/lib/utils';
import React, { useState } from 'react'
import { IoClose } from 'react-icons/io5';
import { toast } from 'react-toastify';
import { clearUserState, selectUserState, setUserState } from '../../redux/reducers/appStateReducer';
import { backend_url } from '../../utils/be';
import { useDispatch, useSelector } from 'react-redux';
import { User } from '../../models/user.model';
import { languagesData } from '../../utils/lang';
import { AuthService } from '../../api/auth.api';

const ProfileCompletion: React.FC<{ isOpen: boolean, onDidDismiss: () => void }> = ({ isOpen, onDidDismiss }) => {
    const user = useSelector(selectUserState)
    const [familyName, setFamilyName] = useState(user?.family_name);
    const [country, setCountry] = useState(user?.country);
    const [phone, setPhone] = useState(user?.phone);
    const [language, setLanguage] = useState(user?.language);
    const [postalAddress, setPostalAddress] = useState('');
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        const data = {
            family_name: familyName,
            country,
            phone,
            language,
            postal_address: postalAddress
        };

        setLoading(true)
        const res = await axios.put(
            `${backend_url}/user/${user?.id}`, { ...data, changes: Object.keys(data) }, {
            headers: {
                "Authorization": `Bearer ${user?.token}`
            }
        },

        );
        if (res.data.success) {
            user && dispatch(setUserState({ ...user, ...res.data } as User))
            toast.success(res.data.message);
            //clear state
            setFamilyName("")
            setCountry("")
            setPhone("")
            setLanguage("")
            setPostalAddress("")
            onDidDismiss()


        } else {
            toast.error(res.data.message);
        }
        setLoading(false)


    };

    return (
        <IonModal isOpen={isOpen} onDidDismiss={onDidDismiss}>
            <IonHeader className="ion-no-shadow">
                <IonToolbar className="px-4">
                    <IonTitle>Complete Your Profile</IonTitle>
                    <IonButton onClick={onDidDismiss} fill='clear' slot='end'>
                        Skip for now
                    </IonButton>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <form onSubmit={handleSubmit}>
                    <div className="p-4">
                        <p className="text-gray-500">Please fill the following info</p>
                        <IonItem>
                            <IonLabel position="floating">Family Name</IonLabel>
                            <IonInput required value={familyName} onIonInput={e => setFamilyName(e.detail.value!)} />
                        </IonItem>
                        <IonItem>
                            <IonLabel >Your Country</IonLabel>
                            <IonSelect value={country} onIonChange={e => setCountry(e.detail.value)}>
                                <IonSelectOption value="Italy">Italy</IonSelectOption>
                                <IonSelectOption value="Germany">Germany</IonSelectOption>
                                <IonSelectOption value="Britain">Britain</IonSelectOption>
                            </IonSelect>
                        </IonItem>
                        <IonItem>
                            <IonLabel position="floating">Phone </IonLabel>
                            <IonInput required value={phone} onIonInput={e => setPhone(e.detail.value!)} />
                        </IonItem>
                        <IonItem>
                            <IonLabel >Language</IonLabel>
                            <IonSelect value={language} onIonChange={e => setLanguage(e.detail.value)}>
                                {
                                    languagesData.map((lang, i) => <IonSelectOption key={i} value={lang}>{lang}</IonSelectOption>)
                                }
                            </IonSelect>
                        </IonItem>
                        <IonItem>
                            <IonLabel position="floating">Postal Address</IonLabel>
                            <IonInput required value={postalAddress} onIonInput={e => setPostalAddress(e.detail.value!)} />
                        </IonItem>
                        <IonToolbar className="mt-10">
                            <IonButton expand="block" mode="ios" type="submit">
                                {loading ? <IonSpinner /> : "Save"}
                            </IonButton>
                        </IonToolbar>
                    </div>
                </form>
            </IonContent>
        </IonModal>
    );
}

export default ProfileCompletion;