/* eslint-disable react-hooks/exhaustive-deps */
import { ethers } from "ethers";
import { useEffect, useState } from "react";
import useConnector from "./useConnector";
import config from "../config";
import trgy from "../contracts/token.json";
import { toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import { updateTRGYBal } from "../redux/reducers/twinergyReducer";

const useTRGY = () => {
  const { address } = useSelector((state) => state.twinergy);
  const { provider } = useConnector();
  const [contract, setContract] = useState(null);
  const dispatch = useDispatch();
  const { location } = window

  useEffect(() => {
    if (provider) {
      const signer = provider.getSigner();
      setContract(new ethers.Contract(config.TRGY, trgy, signer));
    }
  }, [provider, location.pathname]);

  useEffect(() => {
    getBalance();
  }, [address, location.pathname]);

  const approve = async (_depositAmount, spender) => {
    try {
      const tx = await contract.approve(
        spender,
        ethers.utils.parseUnits(String(_depositAmount), "ether"),
        {
          gasLimit: 100000,
        }
      );
      await tx.wait();
      toast.success("Transaction Approved !");
      return true;
    } catch (error) {
      console.error(error);
      toast.error("Transaction Failed !");
      return false;
    }
  };

  const getBalance = async () => {
    const balance = await contract?.balanceOf(address);
    if (!Number(balance?._hex)) return dispatch(updateTRGYBal("0.0"));

    dispatch(updateTRGYBal(Number(balance?._hex) / 10 ** 18));
  };
  return { approve };
};

export default useTRGY;
