import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import axios from "axios";
import { backend_url } from "../utils/be";
import { useDispatch, useSelector } from "react-redux";
import { clearUserState, selectUserState, setUserState } from "../redux/reducers/appStateReducer";
import { RootState } from "../redux/store";
import { IonButton, IonSpinner } from "@ionic/react";
import TwoFaAuthSetting from "../components/account/TwoFaAuthSetting";
import { User } from "../models/user.model";

export default function AccountPage() {
  const user = useSelector(selectUserState)

  const [name, setName] = useState(user?.name);
  const [familyName, setFamilyName] = useState(user?.family_name);
  const [identification, seIdentification] = useState(user?.identificationNumber);
  const [phone, setPhone] = useState(user?.phone);
  const [id, setId] = useState(user?.id);
  const [address, setAddress] = useState(user?.postal_address);
  const [dwelling, setDwelling] = useState(user?.dwelling_id);
  const [language, setLanguage] = useState(user?.language);
  const [country, setCountry] = useState(user?.country);
  const { address: walletAddress } = useSelector((state: RootState) => state.twinergy);
  const [wallet, setWallet] = useState(walletAddress)
  const [walletError, setWalletError] = useState("")
  const [loading, setLoading] = useState(false)

  const dispatch = useDispatch()



  const editPersonal = async (e: any) => {
    e.preventDefault();
    const data = { phone: phone, id_number: id + "", postal_address: address, }
    let user_id = user?.id
    setLoading(true)
    const res = await axios.put(
      `${backend_url}/user/${user_id}`,
      { ...data, changes: Object.keys(data) },
    );
    if (res.data.success) {
      dispatch(clearUserState())
      dispatch(setUserState({ ...user, ...data } as User))
      toast.success(res.data.message);
    } else {
      toast.error(res.data.message);
    }
    setLoading(false)

  };



  const editProfile = async (e: any) => {
    e.preventDefault();

    const data = { language: language, country: country, wallet: wallet ?? walletAddress, dwelling, };
    let user_id = user?.id
    setLoading(true)
    const res = await axios.put(
      `${backend_url}/user/${user_id}`,
      { ...data, changes: Object.keys(data) },
      {
        headers: {
          "ngrok-skip-browser-warning": true,
        },
      }
    );
    if (res.data.success) {
      dispatch(clearUserState())
      dispatch(setUserState(res.data.data))
      toast.success(res.data.message);
    } else {

      toast.error(res.data.message);
    }
    setLoading(false)
  };



  useEffect(() => {
    setWalletError("")
    if (wallet != walletAddress && walletAddress) {
      setTimeout(() => {
        setWallet(walletAddress)
        setWalletError('Please disconnect your wallet to edit address')
      }, 1000);
    }
  }, [wallet])


  return (
    <div className="grid lg:grid-cols-2 gap-6 mt-10" style={{ padding: 30 }}>
      <div className="meter-card p-6 px-10" style={{ position: "relative" }}>
        <div className="font-semibold text-3xl mb-3">
          Personal data
        </div>
        <div className="mb-4 font-semibold">
          <label htmlFor="name">Name*</label>
          <input
            id="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            type="text"
            placeholder="Elena"
            className="block border-b border-gray-500 outline-none w-full py-2 placeholder:font-light"
          />
        </div>
        <div className="mb-4 font-semibold">
          <label htmlFor="familyName">Family Name*</label>
          <input
            id="familyName"
            value={familyName}
            onChange={(e) => setFamilyName(e.target.value)}
            type="text"
            placeholder="Stanley"
            className="block border-b border-gray-500 outline-none w-full py-2 placeholder:font-light"
          />
        </div>
        <div className="mb-4 font-semibold">
          <label htmlFor="phone">Phone</label>
          <input
            id="phone"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            type="text"
            placeholder="0044  6837 267584"
            className="block border-b border-gray-500 outline-none w-full py-2 placeholder:font-light"
          />
        </div>
        <div className="mb-4 font-semibold">
          <label htmlFor="identification">Identification number or passport</label>
          <input
            id="identification"
            type="text"
            value={identification}
            onChange={(e) => seIdentification(e.target.value)}
            placeholder="PA0940562"
            className="block border-b border-gray-500 outline-none w-full py-2 placeholder:font-light"
          />
        </div>
        <div className="mb-4 font-semibold">
          <label htmlFor="address">Postal Address</label>
          <input
            id="address"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
            type="text"
            placeholder="63 Sandyhill Rd, Langford, LD2 0AR, United Kingdom"
            className="block border-b border-gray-500 outline-none w-full py-2 placeholder:font-light"
          />
        </div>
        <div className="mb-4 font-semibold text-end">
          <button
            onClick={editPersonal}
            className="w-[100px] rounded-xl bg-[#7EDD62] text-white p-2"
          >
            {loading ? <IonSpinner color={"dark"} name="dots" /> : "Edit"}
          </button>
        </div>
      </div>
      <div className="meter-card p-6 px-10 relative" style={{ position: "relative" }}>
        <div className="font-semibold text-3xl mb-3">
          Profile Data
        </div>
        <div className="mb-4 font-semibold">
          <label htmlFor="dwelling">Dwelling*</label>
          <input
            id="dwelling"
            value={dwelling}
            onChange={(e) => setDwelling(e.target.value)}
            type="text"
            placeholder="Pilot 1"
            className="block border-b border-gray-500 outline-none w-full py-2 placeholder:font-light"
          />
        </div>
        <div className="mb-4 font-semibold">
          <label htmlFor="language">Language*</label>
          <input
            id="language"
            value={language}
            onChange={(e) => setLanguage(e.target.value)}
            type="text"
            placeholder="English"
            className="block border-b border-gray-500 outline-none w-full py-2 placeholder:font-light"
          />
        </div>
        <div className="mb-4 font-semibold">
          <label htmlFor="country">Country</label>
          <input
            id="country"
            value={country}
            onChange={(e) => setCountry(e.target.value)}
            type="text"
            placeholder="United Kingdoms"
            className="block border-b border-gray-500 outline-none w-full py-2 placeholder:font-light"
          />
        </div>
        <div className="mb-4 font-semibold">
          <label htmlFor="wallet">Wallet Address</label>
          <input
            id="wallet"
            type="text"
            placeholder="Add wallet Address"
            value={wallet}
            onChange={(e) => setWallet(e.target.value!)}
            className="block border-b border-gray-500 outline-none w-full py-2 placeholder:font-light"
          />
        </div>
        {walletError && (
          <div className="text-red-500 text-sm">{walletError}</div>
        )}
        <div className="mb-4 font-semibold text-end lg:absolute bottom-6 right-6">
          <button
            onClick={editProfile}
            className="w-[100px] rounded-xl bg-[#7EDD62] text-white p-2"
            name="edit"
          >
            {loading ? <IonSpinner color={"dark"} name="dots" /> : "Edit"}
          </button>
        </div>
      </div>
      <div className="meter-card p-6 px-10" style={{ position: "relative" }}>
        <div className="font-semibold text-3xl mb-3">Security</div>
        <section>
          <TwoFaAuthSetting />
        </section>
      </div>
    </div>
  );
}
