import React, { useState } from "react";
import {
    BsBoxArrowInDownLeft,
    BsArchive,
    BsCalendar4,
    BsGrid,
} from "react-icons/bs";
import { GiCrystalShine } from "react-icons/gi";
import { IoCopyOutline, IoSearchOutline } from "react-icons/io5";
import { ITradeTransaction } from "../../types/trade";
import axios from "axios";
import { backend_url } from "../../utils/be";
import { IonSpinner } from "@ionic/react";
import { toast } from "react-toastify";
import useEGYVault from "../../hooks/useEGYVault"
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { useTimeAgo } from "../../utils/time";

const TransactionCard: React.FC<{
    item: ITradeTransaction;
    onUpdate?: () => void;
}> = ({ item }) => {
    const { address } = useSelector((state: RootState) => state.twinergy);
    const timeAgo = useTimeAgo(item.updatedAt)


    return (
        <section
            className="grid grid-cols-4 text-sm text-gray-500"
        >
            <div
                className={
                    item.sellerAdress?.toLowerCase() === address?.toLowerCase()
                        ? "text-yellow-600"
                        : "text-primaryGreen"
                }
            >
                {((+item.price) / +item.total_kw).toFixed(2)}
            </div>
            <div>{(+item.total_kw).toFixed(2)}</div>
            <div>{(+item.price).toFixed(2)}</div>
            <div>{timeAgo}</div>
        </section>)

}



export default TransactionCard;
