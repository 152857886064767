import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { IoClose, IoFlash } from "react-icons/io5";
import { GoDatabase } from "react-icons/go";
import axios from "axios";
import { backend_url } from "../../utils/be";
import { useSelector } from "react-redux";
import { selectUserState } from "../../redux/reducers/appStateReducer";
import { IonSpinner, useIonToast } from "@ionic/react";
import useEGYVault from "../../hooks/useEGYVault";
import useTRGY from "../../hooks/useTRGY";
import config from "../../config";
import { toast } from "react-toastify";
import { RootState } from "../../redux/store";
import { v4 as uuidv4 } from "uuid";
import { motion } from "framer-motion"



const ConfirmPurchase: React.FC<{
  open: boolean;
  onClose: () => void;
  transaction: any;
}> = ({ open, onClose, transaction }) => {
  const user = useSelector(selectUserState);
  const [loading, setLoading] = useState(false);
  const [approved, setApproved] = useState(false);
  const { buyEnergy } = useEGYVault()
  const { approve } = useTRGY()
  const { address } = useSelector((state: RootState) => state.twinergy);
  const isSeller = address.toLowerCase() === transaction.seller?.toLowerCase()

  const handleApprove = async () => {
    setLoading(true);
    let res = await approve(transaction.price, config.energy_vault)
    if (res) {
      setApproved(true)
    } else {
      setApproved(false)
    }
    setLoading(false);

  }

  async function handleConfirm() {
    setLoading(true);

    try {
      let onChainTxnId = uuidv4();
      await buyEnergy(transaction.seller, transaction.index, onChainTxnId)

      await axios.post(`${backend_url}/user/create_transaction`, {
        userId: user?.id,
        status: "pending",
        type: "buy",
        total_kw: transaction.energy,
        sellerAddress: transaction.seller,
        onChainTxnId,
        price: transaction.price,
      });

      toast.success("Transaction created successfully");
      window.location.reload();
      onClose();
    } catch (error: any) {
      toast.error(error.response?.data.message || error.message);
      console.log(error);

    }
    setApproved(false);
    setLoading(false);
  }

  return (
    <Modal show={open} onHide={onClose}>
      <Modal.Header>
        <Modal.Title>{!isSeller ? "Confirm Purchase" : "Order Info"}</Modal.Title>
        <IoClose onClick={onClose} className="text-2xl" />
      </Modal.Header>
      <Modal.Body className="text-center">
        <div className="text-center">
          <div className="flex items-center justify-center mb-2">
            <IoFlash className="text-primaryGreen" /> {transaction.energy} KWh
          </div>
        </div>
        <div className="border p-2 rounded-md">
          <div className="flex justify-between text-sm">
            <span>Seller address</span> <span>Energy Price</span>
          </div>
          <div className="flex justify-between text-sm">
            <span>{`${transaction.seller?.slice(
              0,
              6
            )}...${transaction.seller?.slice(38, 42)}`}</span>{" "}
            <span className="flex items-center ">
              <GoDatabase className="text-primaryGreen" />{" "}
              {transaction.price?.toFixed(3)} TRGY
            </span>
          </div>
        </div>
        <div className="my-2 px-2 text-gray-600">
          <div className="flex justify-between text-sm">
            <span>Total</span>{" "}
            <span className="flex items-center ">
              <GoDatabase className="text-primaryGreen mr-2" />{" "}
              {transaction.price?.toFixed(3)} TRGY
            </span>
          </div>
        </div>
      </Modal.Body>
      {!isSeller && <Modal.Footer
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <motion.button
          onClick={() => onClose()}
          animate={{ width: approved ? "20%" : "45%" }}
          className=" bg-primaryGreen text-white p-2 rounded-md"
        >
          Reject
        </motion.button>
        <motion.button
          animate={{ width: approved ? "70%" : "45%", background: approved ? "#FFBA4D" : "rgb(126 221 98)" }}
          onClick={() => approved ? handleConfirm() : handleApprove()}
          className=" bg-primaryGreen text-white p-2 rounded-md"
        >
          {
            approved ? <>
              {loading ? <IonSpinner /> : "Confirm Purchase"}
            </> : <>
              {loading ? <IonSpinner /> : "Approve"}

            </>
          }
        </motion.button>
      </Modal.Footer>}
    </Modal>
  );
};

export default ConfirmPurchase;
