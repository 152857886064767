export const HomeIcon = {
  icon: [
    // SVG viewbox width (in pixels)
    24,

    // SVG viewbox height (in pixels)
    24,

    // Aliases (not needed)
    [],

    // Unicode as hex value (not needed)
    "",

    // SVG path data
    "M20.5125 9.72208L13.0125 2.90646C12.736 2.65348 12.3748 2.51318 12 2.51318C11.6252 2.51318 11.264 2.65348 10.9875 2.90646L3.4875 9.72208C3.33394 9.86257 3.2113 10.0335 3.12737 10.2239C3.04344 10.4144 3.00007 10.6202 3 10.8283V19.4627C2.9939 19.8409 3.12741 20.208 3.375 20.494C3.51552 20.6537 3.68862 20.7816 3.88267 20.8689C4.07672 20.9562 4.28721 21.001 4.5 21.0002H9C9.19891 21.0002 9.38968 20.9212 9.53033 20.7805C9.67098 20.6399 9.75 20.4491 9.75 20.2502V15.7502C9.75 15.5513 9.82902 15.3605 9.96967 15.2199C10.1103 15.0792 10.3011 15.0002 10.5 15.0002H13.5C13.6989 15.0002 13.8897 15.0792 14.0303 15.2199C14.171 15.3605 14.25 15.5513 14.25 15.7502V20.2502C14.25 20.4491 14.329 20.6399 14.4697 20.7805C14.6103 20.9212 14.8011 21.0002 15 21.0002H19.5C19.7488 21.0024 19.994 20.9411 20.2125 20.8221C20.4502 20.6926 20.6488 20.5016 20.7873 20.269C20.9259 20.0365 20.9993 19.7709 21 19.5002V10.8283C20.9999 10.6202 20.9566 10.4144 20.8726 10.2239C20.7887 10.0335 20.6661 9.86257 20.5125 9.72208Z",
  ],
  iconName: "Home-Icon",
  prefix: "simple-icons",
};
