/* eslint-disable import/no-anonymous-default-export */
export default {
  treasury: "0xA610e21c4a0d9116A3bB65E2F6E5D814cadE3346",
  TRGY: "0x4df7Dc34b4aF0CD7D04D25a5f0C8ea4cb6B31816",
  FFTM: "0xDd049be3A71a94B14e9Ee03534C33E6B0f8CF96a",
  WATT: "0x15a27768BD762F5F279975eC08F2C0FDA2c1BD8c",
  DRR: "0xff66C6fff0f7cAf9F003c1e2C6adc01433BE1313",
  faucet: "0xa68B2C666f12b7f19961B3375CD3Bea9DF5B9C97",
  trgy_vault: "0x5097221069c453B84ba83E11Df99e9d0Bf315016",
  energy_vault: "0x5c6350B7857B4a60Bd0B7ADB04c2d2Cb808EaA09",
  meter_reading: "0x1C35b3150C68C45D78684e8A41F271ee9A401E5d",
  order_book: "0x1a196570B2567e1A7d7adE9C34EB4607cEef7FfD",
  gas_station: "0x3f7D91cB5D745b3d7FFaB1BFf8fe352be53C217F",
};
