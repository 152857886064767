import { useEffect, useState } from "react";
import { ethers } from "ethers";
import _config from "../config";
import faucet from "../contracts/faucet.json";
import useConnector from "./useConnector";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

const useFaucet = () => {
  const { address } = useSelector((state) => state.twinergy);
  const [_address, setAddress] = useState("");
  const { provider } = useConnector();
  const [contract, setContract] = useState(null);
  const { location } = window;

  useEffect(() => {
    if (provider) {
      const signer = provider.getSigner();
      setContract(new ethers.Contract(_config.faucet, faucet, signer));
    }
  }, [provider, location.pathname]);

  useEffect(() => {
    setAddress(address)
  }, [address, location.pathname]);

  const getFFTM = async () => {
    try {
      const tx = await contract.getAsset("FFTM", _address);
      await tx.wait()
      toast.success("Transaction Successful !!");
    } catch (error) {
      console.error(error);
      toast.error("Transaction Failed !!");
    }
  };

  return { getFFTM, _address, setAddress };
};

export default useFaucet;
