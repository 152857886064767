import React, { useContext } from 'react'
import { OrderBookContext } from '../../pages/NewTradePage'
import useOrderBook from '../../hooks/useOrderBook';

const MarketStats = () => {
    const { orderBook: [orderBookData, setOrderBookData] } = useContext(OrderBookContext)
    const { fee } = useOrderBook();


    return (
        <div className='border-r border-gray-200 text-center py-2 flex flex-col flex-auto'>
            <section>
                <div className='py-2 border-b'>
                    Market Sell Orders
                </div>
                <header className='grid grid-cols-3 '>
                    <div>Price (TRGY)</div>
                    <div>Volume (kWh)</div>
                    <div>Total</div>
                </header>
                <section className='h-[40vh] overflow-hidden'>
                    {orderBookData?.sellOrders.combinedSellOrders.map((values, index) => {
                        if (values.energyAmount === 0) return;
                        const total = Math.abs(values.total - fee)
                        return (
                            <section key={index} className='grid grid-cols-3 text-sm text-gray-500' >
                                <div className='text-yellow-600'>{values.pricePerKW.toFixed(2)}</div>
                                <div>{values.energyAmount.toFixed(2)}</div>
                                <div>{(total).toFixed(2)}</div>
                            </section>)
                    })
                    }
                </section>
            </section>
            <div className='py-2 border-y'>
                Market Buy Orders
            </div>
            <section>
                <header className='grid grid-cols-3 '>
                    <div>Price (TRGY)</div>
                    <div>Volume (kWh)</div>
                    <div>Total</div>
                </header>
                <section className='h-[40vh] overflow-hidden'>
                    {orderBookData?.buyOrders.combinedBuyOrders?.map((values, index) => {

                        if (values.energyAmount === 0) return;
                        return (
                            <section key={index} className='grid grid-cols-3 text-sm text-gray-500' >
                                <div className='text-green-600'>{values.pricePerKW.toFixed(2)}</div>
                                <div>{values.energyAmount.toFixed(2)}</div>
                                <div>{values.total.toFixed(2)}</div>
                            </section>)
                    })
                    }
                </section>
            </section>
        </div>
    )
}

export default MarketStats



const sellValues = [
    { kWh: 100, price: 10, total: 1000 },
    { kWh: 200, price: 15, total: 3000 },
    { kWh: 150, price: 12, total: 1800 },
    { kWh: 120, price: 8, total: 960 },
    { kWh: 90, price: 9, total: 810 },
    { kWh: 180, price: 11, total: 1980 },
    { kWh: 300, price: 13, total: 3900 },
    { kWh: 250, price: 14, total: 3500 },
    { kWh: 80, price: 7, total: 560 },
    { kWh: 160, price: 10, total: 1600 },
    { kWh: 220, price: 12, total: 2640 },
    { kWh: 130, price: 9, total: 1170 },
    { kWh: 240, price: 13, total: 3120 },
    { kWh: 170, price: 11, total: 1870 },
    { kWh: 190, price: 12, total: 2280 },
    { kWh: 110, price: 8, total: 880 },
    { kWh: 140, price: 9, total: 1260 },
    { kWh: 260, price: 14, total: 3640 },
    { kWh: 350, price: 16, total: 5600 },
    { kWh: 400, price: 18, total: 7200 },
    { kWh: 130, price: 9, total: 1170 },
    { kWh: 240, price: 13, total: 3120 },
    { kWh: 170, price: 11, total: 1870 },
    { kWh: 190, price: 12, total: 2280 },
    { kWh: 110, price: 8, total: 880 },
    { kWh: 140, price: 9, total: 1260 },
    { kWh: 260, price: 14, total: 3640 },
    { kWh: 350, price: 16, total: 5600 },
    { kWh: 400, price: 18, total: 7200 }
];
