import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axios, { AxiosHeaders } from "axios";
import { toast } from "react-toastify";
import {
    Form,
    Button,
    FormCheck,
    Row,
    Col,
    Card,
} from "react-bootstrap";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { backend_url } from "../utils/be";
import { IonCheckbox, IonSpinner, useIonToast } from "@ionic/react";
import footerImage from "../asserts/images/footer_flag.svg";
import "./../styles/login.css";
import jwt from "jwt-decode";
import { endpoint } from "../utils/endpoint";


export default function VerifyEmail() {
    let navigate = useNavigate();
    const { token } = useParams()
    const [toast] = useIonToast()

    const headers = {
        "ngrok-skip-browser-warning": true,
    }

    useEffect(() => {

        if (token) {
            axios.get(`${backend_url}/user/verify/${token}`, {
                headers
            }).then(res => {
                console.log(res.data)
                if (res.data.success) {
                    toast({ message: "Verification Successfull!", buttons: [{ text: "Ok" }], color: "warning", position: "bottom" })
                    navigate("/signin", { replace: true })
                }
            }).catch(err => {
                console.log(err)
                toast({ message: err.message || JSON.stringify(err), buttons: [{ text: "Ok" }], color: "warning", position: "bottom" })
            })
        }

    }, [token])




    return (
        <React.Fragment>
            <Row className="col-container">
                <Col className="left-col " sm="12" md="6">
                    {token ? <div>
                        <h1 className="">{<IonSpinner color={"success"} />}Verifying Email</h1>
                        <p className="text-gray-500 font-light">
                            Please wait while we verify your email
                        </p>
                    </div> :
                        <div className="p-5 ">
                            <p className="text-gray-500 font-light border-[1px] rounded-sm border-yellow-500 p-2">
                                Please check your email and click on the link again
                            </p>
                        </div>
                    }
                </Col>
                <Col className="right-col relative">
                    <Card
                        style={{ width: "18rem", border: 0, boxShadow: "none" }}
                        className="right-card"
                    >
                        <Card.Img
                            variant="top"
                            src={require("./../asserts/images/logo.svg").default}
                        />
                        <Card.Text className="right-text">
                            Transactive Energy Module
                        </Card.Text>
                    </Card>
                    <div className="absolute bottom-0 text-left w-full px-4 lg:px-10 py-4 text-[#8F9BBA] items-center text-xs flex">
                        <img src={footerImage} className="w-14 rounded-sm" alt="footer image" />
                        <div className="mx-2">
                            Twinergy has received funding from the European Union's Horizon 2020 Research and Innovation Programme under Grant Agreement No. 957736
                        </div>
                    </div>
                </Col>
            </Row>
        </React.Fragment>
    );
}

const styles = {
    "login-input": "inline-block w-full placeholder:font-light placeholder:text-gray-600 border-gray-600 border-[0.5px] rounded-xl p-2 pl-3 outline-yellow-500"
}
