/* eslint-disable no-loop-func */
/* eslint-disable react-hooks/exhaustive-deps */
import { ethers } from "ethers";
import { useEffect, useState } from "react";
import useConnector from "./useConnector";
import config from "../config";
import order_book from "../contracts/orderbook.json";
import useMeters from "./useMeters";
import { useSelector } from "react-redux";
import RootState from "../redux/store";

const useOrderBook = () => {
  const { provider } = useConnector();
  const { address } = useSelector((state) => state.twinergy)
  const [contract, setContract] = useState(null);
  const { batMeterAddress } = useMeters();
  const [fee, setFee] = useState(0);
  const [spread, setSpread] = useState(0)
  const location = window.location;

  useEffect(() => {
    if (provider && address) {
      const signer = provider.getSigner();
      setContract(new ethers.Contract(config.order_book, order_book, signer));
    }
  }, [provider, location.pathname, address]);

  useEffect(() => {
    if (contract && address) {
      contract.fee().then(fee => {
        setFee(+ethers.utils.formatUnits(fee, 18));
      })

      contract.spread().then(spread => {
        setSpread(+ethers.utils.formatUnits(spread, 18));
      })

    }
  }, [contract, address])

  const getTimeStamp = () => {
    const now = new Date();

    // Get the individual components of the date and time
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0"); // Adding 1 to the month since it is zero-based
    const day = String(now.getDate()).padStart(2, "0");
    const hours = String(now.getHours()).padStart(2, "0");
    const minutes = String(now.getMinutes()).padStart(2, "0");
    const seconds = String(now.getSeconds()).padStart(2, "0");

    // Format the date and time
    const formattedDateTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    return formattedDateTime;
  };

  const createSellOrder = async (energyAmount, price, pricePerKw) => {
    try {
      let timestamp = getTimeStamp();
      const txn = await contract?.createSellOrder(
        ethers.utils.parseUnits(String(energyAmount), "ether"),
        ethers.utils.parseUnits(String(price), "ether"),
        timestamp,
        ethers.utils.parseUnits(String(pricePerKw), "ether"),
      );
      await txn.wait();
      return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  const cancelSellOrder = async (index) => {
    try {
      const txn = await contract?.cancelSellOrder(index);
      await txn.wait();
      return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  const createBuyOrder = async (energyAmount, price, partFill, pricePerKw) => {

    try {
      let timestamp = getTimeStamp();
      const txn = await contract?.createBuyOrder(
        ethers.utils.parseUnits(String(energyAmount), "ether"),
        ethers.utils.parseUnits(String(price), "ether"),
        timestamp,
        batMeterAddress,
        partFill,
        ethers.utils.parseUnits(String(pricePerKw), "ether"),
      );
      await txn.wait();
      return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  const cancelBuyOrder = async (index, partFill) => {
    try {
      const txn = await contract?.cancelBuyOrder(index, partFill);
      await txn.wait();
      return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  return {
    createSellOrder,
    cancelSellOrder,
    createBuyOrder,
    cancelBuyOrder,
    fee,
    spread
  };
};

export default useOrderBook;
