import { ethers } from "ethers";
import { useEffect, useState } from "react";
import useConnector from "./useConnector";
import config from "../config";
import trgy_vault from "../contracts/trgy_vault.json";
import { toast } from "react-toastify";


const useTRGYVault = () => {
  const { provider } = useConnector();
  const [contract, setContract] = useState(null);
  const { location } = window

  useEffect(() => {
    if (provider) {
      const signer = provider.getSigner();
      setContract(new ethers.Contract(config.trgy_vault, trgy_vault, signer));
    }
  }, [provider, location.pathname]);

  const mint = async (amount) => {
    try {
      const tx = await contract.mint(ethers.utils.parseUnits(String(amount), "ether"))
      await tx.wait()
      toast.success("Transaction successfull !!")
      window.location.reload()
    } catch (error) {
      console.error(error)
      toast.error("Transaction failed !!")

    }
  }
  const withdraw = async (amount) => {
    try {
      const tx = await contract.withdraw(ethers.utils.parseUnits(String(amount), "ether"), "FTM")
      await tx.wait()
      toast.success("Transaction successfull !!")
      window.location.reload()
    } catch (error) {
      console.error(error)
      toast.error("Transaction failed !!")

    }
  }
  return { mint, withdraw }

}

export default useTRGYVault;
