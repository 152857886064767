import React, { useState, useEffect } from "react";
import { Row, Col, Card, Form, Button, Image } from "react-bootstrap";
import "./../styles/walletpage.css";
import styles from "./../styles/meters.module.css";
import Select from "react-select";
import styles2 from "./../styles/wallet.module.css";
import useMeters from "../hooks/useMeters";
import useGasStation from "../hooks/useGasStation";

export default function MetersPage() {
  const { meters, fundMeters } = useGasStation();
  const {
    mainMeterBal,
    mainMeterGasFee,
    pvMeterBal,
    batMeterBal,
    batMainMeterBal,
    mainMeterAddress,
    pvMeterAddress,
    batMainMeterAddress,
    batMeterAddress,
    pvMeterGasFee,
    batMainMeterGasFee,
    batMeterGasFee,
  } = useMeters();
  const [charge_meter, setcharge_meter] = useState(false);
  const [amount, setAmount] = useState(0);
  const [selectedMeters, setmeter] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  let options = [
    {
      value: "all",
      label: "All meters",
    },
    ...meters,
  ];
  const changeMeter = (e: any) => {
    if (e.value === "all") {
      setmeter(meters);
    } else {
      setmeter([e]);
    }
    if (options.length > 1) setcharge_meter(true);
  };

  const handleMouseEnter = (e: any) => {
    e.target.style.color = "#06152b";
  };
  const handleMouseLeave = (e: any) => {
    e.target.style.color = "white";
  };

  const handleFundmeter = async () => {
    if (loading) return;
    if (!amount) return;
    setLoading(true);
    let metersToFund = [];
    for (let value of selectedMeters) {
      metersToFund.push(value.wallet_address);
    }
    await fundMeters(amount, metersToFund);
    setLoading(false);
  };

  return (
    <Row className="main-container">
      <Col className="card-container">
        <div style={{ padding: 30 }} className="lg:grid grid-cols-2 lg:gap-4 gap-6">
          <div className={"meter-card"}>
            <Card.Body>
              <Card.Title className={styles2.card_title}>
                Main meter
                <a
                  href={`https://testnet.ftmscan.com/address/${mainMeterAddress}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <Image
                    style={{
                      cursor: "pointer",
                    }}
                    src={require("./../asserts/icons/arrowicon.svg").default}
                    className="icon"
                  />
                </a>
              </Card.Title>
              <Card.Subtitle className={styles2.card_subtitle}>
                Main meter balance (FTM)
              </Card.Subtitle>
              <hr />
              <Form>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <div className={styles2.disp}>
                    <Image
                      src={require("./../asserts/icons/sym3.svg").default}
                      className={styles2.dispicon}
                    />
                    <div className={styles2.dispval}>{mainMeterBal}</div>
                  </div>
                </Form.Group>
                <Card.Subtitle className={styles2.card_subtitle}>
                  Gas fees (FTM)
                </Card.Subtitle>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <hr />
                  <div className={styles2.disp}>
                    <Image
                      src={require("./../asserts/icons/sym3.svg").default}
                      className={styles2.dispicon}
                    />
                    <div className={styles2.dispval}>{mainMeterGasFee}</div>
                  </div>
                </Form.Group>
              </Form>
            </Card.Body>
          </div>
          <div className={"meter-card"}>
            <Card.Body>
              <Card.Title className={styles2.card_title}>
                PV meter{" "}
                <a
                  href={`https://testnet.ftmscan.com/address/${pvMeterAddress}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <Image
                    src={require("./../asserts/icons/arrowicon.svg").default}
                    className="icon"
                  />
                </a>
              </Card.Title>
              <Card.Subtitle className={styles2.card_subtitle}>
                PV meter balance (FTM)
              </Card.Subtitle>
              <hr />
              <Form>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <div className={styles2.disp}>
                    <Image
                      src={require("./../asserts/icons/sym3.svg").default}
                      className={styles2.dispicon}
                    />
                    <div className={styles2.dispval}>{pvMeterBal}</div>
                  </div>
                </Form.Group>

                <Card.Subtitle className={styles2.card_subtitle}>
                  Gas fees (FTM)
                </Card.Subtitle>

                <hr />
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <div className={styles2.disp}>
                    <Image
                      src={require("./../asserts/icons/sym3.svg").default}
                      className={styles2.dispicon}
                    />
                    <div className={styles2.dispval}>{pvMeterGasFee}</div>
                  </div>
                </Form.Group>
              </Form>
            </Card.Body>
          </div>
          <div className={"meter-card"}>
            <Card.Body>
              <Card.Title className={styles2.card_title}>
                Battery main meter{" "}
                <a
                  href={`https://testnet.ftmscan.com/address/${batMainMeterAddress}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <Image
                    src={require("./../asserts/icons/arrowicon.svg").default}
                    className="icon"
                  />
                </a>
              </Card.Title>
              <Card.Subtitle className={styles2.card_subtitle}>
                Battery Main meter balance (FTM)
              </Card.Subtitle>
              <hr />
              <Form>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <div className={styles2.disp}>
                    <Image
                      src={require("./../asserts/icons/sym3.svg").default}
                      className={styles2.dispicon}
                    />
                    <div className={styles2.dispval}>{batMainMeterBal}</div>
                  </div>
                </Form.Group>

                <Card.Subtitle className={styles2.card_subtitle}>
                  Gas fees (FTM)
                </Card.Subtitle>
                <hr />
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <div className={styles2.disp}>
                    <Image
                      src={require("./../asserts/icons/sym3.svg").default}
                      className={styles2.dispicon}
                    />
                    <div className={styles2.dispval}>{batMainMeterGasFee}</div>
                  </div>
                </Form.Group>
              </Form>
            </Card.Body>
          </div>
          <div className={"meter-card"}>
            <Card.Body>
              <Card.Title className={styles2.card_title}>
                Battery transact meter{" "}
                <a
                  href={`https://testnet.ftmscan.com/address/${batMeterAddress}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <Image
                    src={require("./../asserts/icons/arrowicon.svg").default}
                    className="icon"
                  />
                </a>
              </Card.Title>
              <Card.Subtitle className={styles2.card_subtitle}>
                Battery Transact balance (FTM)
              </Card.Subtitle>
              <hr />
              <Form>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <div className={styles2.disp}>
                    <Image
                      src={require("./../asserts/icons/sym3.svg").default}
                      className={styles2.dispicon}
                    />
                    <div className={styles2.dispval}>{batMeterBal}</div>
                  </div>
                </Form.Group>

                <Card.Subtitle className={styles2.card_subtitle}>
                  Gas fees (FTM)
                </Card.Subtitle>
                <hr />
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <div className={styles2.disp}>
                    <Image
                      src={require("./../asserts/icons/sym3.svg").default}
                      className={styles2.dispicon}
                    />
                    <div className={styles2.dispval}>{batMeterGasFee}</div>
                  </div>
                </Form.Group>
              </Form>
            </Card.Body>
          </div>
          <div className={"meter-card"}>
            <Card.Body>
              <Card.Title className={styles2.card_title}>
                Smart meter charging station{" "}
                <Image
                  src={require("./../asserts/icons/arrowicon.svg").default}
                  className="icon"
                />
              </Card.Title>
              <Card.Subtitle className={styles2.card_subtitle}>
                Charge smart meter wallets
              </Card.Subtitle>
              <hr />
              <Select
                placeholder="Select meters"
                options={options}
                onChange={changeMeter}
                getOptionLabel={(e: any) => (
                  <>
                    {options.length > 1 ? (
                      <div className={styles.select_option}>
                        {e.dwelling_identifier || e.label}
                      </div>
                    ) : (
                      <div className={styles.select_option}>
                        No meter to show
                      </div>
                    )}
                  </> as any
                )}
              />
              {charge_meter ? (
                <div>
                  <div>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Text className={styles.charge_amount}>
                        Amount
                      </Form.Text>
                      <Form.Control
                        type="text"
                        placeholder="Enter Amount"
                        className="text-in"
                        value={amount}
                        onChange={(e: any) => setAmount(e.target.value)}
                      />
                    </Form.Group>
                  </div>
                  <div>
                    <table className={styles.table}>
                      {selectedMeters.map((meter, index) => (
                        <tr key={index}>
                          <td className={styles.tcell}>
                            {meter.dwelling_identifier}{" "}
                          </td>
                          <td className={styles.tcell}>
                            (
                            {meter.dwelling_identifier === "Main meter" &&
                              mainMeterBal}
                            {meter.dwelling_identifier === "PV meter" &&
                              pvMeterBal}
                            {meter.dwelling_identifier ===
                              "Battery Main meter" && batMainMeterBal}
                            {meter.dwelling_identifier ===
                              "Battery Transact meter" && batMeterBal}
                            )
                          </td>
                          <td
                            className={styles.tcell}
                            style={{ textAlign: "right" }}
                          >
                            {amount / meters.length} FTM
                          </td>
                        </tr>
                      ))}

                      {/* <tr>
                        <td className={styles.tcell} colSpan={2}>
                          Fee
                        </td>

                        <td
                          className={styles.tcell}
                          style={{ textAlign: "right" }}
                        >
                          0.000000230000000000
                        </td>
                      </tr> */}
                      <tr>
                        <td className={styles.tcell} colSpan={2}>
                          Total
                        </td>

                        <td
                          className={styles.tcell}
                          style={{ textAlign: "right" }}
                        >
                          {amount} FTM
                        </td>
                      </tr>
                    </table>
                  </div>
                  <div>
                    <Button
                      className={styles.charge_button}
                      style={{
                        background: "#7edd62",
                        borderRadius: "10px",
                        border: "none",
                        color: "#fff",
                      }}
                      onMouseEnter={handleMouseEnter}
                      onMouseLeave={handleMouseLeave}
                      onClick={handleFundmeter}
                    >
                      {loading ? "Loading ..." : "Charge"}
                    </Button>
                  </div>
                </div>
              ) : null}
            </Card.Body>
          </div>
        </div>
      </Col>
    </Row>
  );
}
