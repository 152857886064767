import { IonButton, IonItem, IonSegment, IonSpinner, useIonToast } from "@ionic/react";
import axios from "axios";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IoClipboardOutline, IoCopyOutline } from "react-icons/io5";
import { Clipboard } from "@capacitor/clipboard";
import { selectUserState, setUserState } from "../../redux/reducers/appStateReducer";
import { backend_url } from "../../utils/be";
import { twinergyImages } from "../../asserts/images";

const TwoFaAuthSetting = () => {
    const [loading, setLoading] = useState(false);
    const user = useSelector(selectUserState)
    const [qrCode, setQrCode] = useState("")
    const [qrKey, setQrKey] = useState("")
    const [otpCode, setOtpCode] = useState("")
    const [toast] = useIonToast()
    const [enabled, setEnabled] = useState(false)
    const dispatch = useDispatch()

    function copyQrKey() {
        Clipboard.write({ string: qrKey });
        toast({ message: "Copied to clipboard", duration: 2000, color: 'success' })
    }
    async function enableTwoFa() {
        if (!user || !user.id) {
            localStorage.clear();
            window.location.href = "/"
            return;
        }
        setLoading(true)
        await axios.get(`${backend_url}/user/get_2fa`, {
            headers: {
                "Authorization": `Bearer ${user.token}`
            }
        }).then(res => {
            const response: {
                fa_data: {
                    fa_key: string,
                    url: string
                },
                success: true
            } = res.data;

            if (response.success) {
                setQrCode(response.fa_data.url)
                setQrKey(response.fa_data.fa_key)
            }


        }).catch(err => {
            console.log(err)
            toast({ message: "Unable to enable 2 Factor Authentication", duration: 2000, color: 'danger' })
        })
        setLoading(false)
    }


    async function setTwoFa(e: any) {
        e.preventDefault();
        if (!user || !user.id) {
            localStorage.clear();
            window.location.href = "/"
            return;
        }
        setLoading(true)
        await axios.post(`${backend_url}/user/set_2fa`, {
            otpCode,
            fa_key: qrKey
        }).then(res => {
            if (res.data.success) {
                toast({ message: "2 Factor Authentication enabled", duration: 2000, color: 'success' })
                dispatch(setUserState({ ...user, fa_enabled: !!qrKey, fa_key: qrKey }))
                setEnabled(true)
            }
            else {
                toast({ message: res.data.message, duration: 2000, color: 'danger' })
            }

        }).catch(err => {
            console.log(err)
            toast({ message: "Unable to enable 2 Factor Authentication", duration: 2000, color: 'danger' })
        })
        setLoading(false)
    }


    useEffect(() => {
        if (!qrCode) {
            enableTwoFa();
        }
    }, [])


    return (
        <>
            {!enabled ? <section>
                {loading && <IonSegment />}
                {qrCode && <div className="text-center">
                    <div className="border w-100 p-2 m-auto my-3">
                        <div className="items-center flex justify-between bg-gray-50 p-2">
                            <p>{qrKey}</p>
                            <IonButton fill="clear" onClick={copyQrKey}>
                                <IoCopyOutline />
                            </IonButton>
                        </div>
                        <img className="w-[300px] m-auto" src={qrCode} alt="2FA authentication QR code" />
                        <span className="text-sm">Scan the QR code to enable 2FA</span>
                    </div>

                    <div>
                        <form onSubmit={setTwoFa} className="text-left mt-3">
                            <div className="text-black font-light mb-3">
                                <div className="text-[14px]">Insert OTP Code</div>
                                <input
                                    required
                                    value={otpCode}
                                    onChange={(e) => setOtpCode(e.target.value)}
                                    style={{ letterSpacing: "5px" }}
                                    placeholder="_ _ _ _ _ _"
                                    className="text-center block border p-2 rounded outline-primaryGreen w-full"
                                    type="number"
                                    aria-label="OTP code input"
                                />
                            </div>

                            <div className="mt-1">
                                <button
                                    type="submit"
                                    name="verify button"
                                    className="p-3 w-full bg-[#7EDD62] rounded-xl text-white md:text-sm"
                                    aria-label="Verify button"
                                >
                                    {!loading ? "Verify" : <IonSpinner color="dark" />}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>}

            </section>
                :
                <section className="text-center">
                    <div>
                        <img src={twinergyImages.twofa} className="w-52 m-auto" alt="2fa enable button" />
                    </div>
                    <div className="text-gray-500 font-bold mt-4">2FA Enabled Already</div>
                </section>}
        </>
    )
}

export default TwoFaAuthSetting
