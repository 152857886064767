import { Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import PurchaseEnergyCard from '../components/TradeEnergy/PurchaseEnergyCard'
import SellEnergyCard from '../components/TradeEnergy/SellEnergyCard'
import { useSelector } from 'react-redux'
import { selectUserState } from '../redux/reducers/appStateReducer'
import BuyOrder from '../components/TradeEnergy/BuyOrder'


const TradePage = () => {
    const user = useSelector(selectUserState)

    return (
        <Row>
            <section className="toolbar p-6 flex text-2xl gap-x-5 ml-8">
                <Link to="/trade" className='text-black font-semibold underline underline-offset-4'>Buy</Link>
                <Link to="/trade/sell" className='text-gray-700 hover:text-black font-semibold underline-offset-4'>Sell</Link>
                <Link to="/trade/transactions" className='text-gray-700 hover:text-black font-semibold underline-offset-4'>Transactions</Link>
            </section>
            <section className='grid lg:grid-cols-2 gap-5'>
                <PurchaseEnergyCard />

                {/* ---------- SELL SECTION --------------- */}
                <BuyOrder />
            </section>
        </Row>
    )
}

export default TradePage