/* eslint-disable react-hooks/exhaustive-deps */
import { ethers } from "ethers";
import { useEffect, useState } from "react";
import useConnector from "./useConnector";
import config from "../config";
import energy_vault from "../contracts/energy_vault.json";
import { toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import {
  updateWATTBal
} from "../redux/reducers/twinergyReducer";


const useWATT = () => {
  const { address } = useSelector((state) => state.twinergy);
  const { provider } = useConnector();
  const [contract, setContract] = useState(null);
  const dispatch = useDispatch()
  const location = window.location
  useEffect(() => {
    if (provider) {
      const signer = provider.getSigner();
      setContract(new ethers.Contract(config.energy_vault, energy_vault, signer));
    }
  }, [provider, location.pathname]);


  useEffect(() => {
    getBalance()
  }, [address, location.pathname]);

  const getBalance = async () => {
    const balance = await contract?.wattUserBalance(address);
    if (!Number(balance?._hex)) return dispatch(updateWATTBal("0.0"))
    dispatch(updateWATTBal((Number(balance?._hex) / 10 ** 18).toLocaleString(
      undefined,
      { minimumFractionDigits: 2 }
    )))
  }

}

export default useWATT
