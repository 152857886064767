import React from 'react'
import wrongwallet from "../asserts/images/wrongwallet.png"
import { useSelector } from 'react-redux'
import { RootState } from '../redux/store'

const WrongWalletPage = () => {
    const { address } = useSelector((state: RootState) => state.twinergy)
    return (
        <div className='p-4 flex justify-center flex-col h-screen text-center'>
            <div className='text'>
                <img className='h-[300px] m-auto' src={wrongwallet} alt="Invalid wallet please connect to right wallet" />
                {address ? <section>
                    <div className='text-xl text-gray-800 font-bold '>Wrong Wallet</div>
                    <div className='text-xl text-gray-400'>Please disconnect and connect to the right wallet</div>
                </section> :
                    <section>
                        <div className='text-xl text-gray-800 font-bold '>No Wallet Connected</div>
                        <div className='text-xl text-gray-400'>Please connect to the right wallet</div>
                    </section>
                }
            </div>
        </div>
    )
}

export default WrongWalletPage