//DRR Reward slice

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DRRReward, DRRToken } from '../../types/rewards';
import { RootState } from '../store';


interface DRRState {
    rewards: DRRReward[],
    token: DRRToken | undefined,
}

const initialState: DRRState = {
    rewards: [],
    token: undefined
}

const DRRReducer = createSlice({
    name: "DERRewards",
    initialState,
    reducers: {
        setRewards: (state, action: PayloadAction<DRRReward[]>) => {
            state.rewards = action.payload;
        },
        setTokens: (state, action: PayloadAction<DRRToken>) => {
            state.token = action.payload;
        }
    }
})


export const { setRewards, setTokens } = DRRReducer.actions;

export const selectRewards = (state: RootState) => state.DRRReward.rewards;
export const selectToken = (state: RootState) => state.DRRReward.token;

export default DRRReducer.reducer;