import React, { useEffect, useState } from 'react'
import { Dropdown } from 'react-bootstrap'
import { FiFilter } from 'react-icons/fi'
import { HiOutlineLightningBolt } from 'react-icons/hi'
import { LuShoppingCart } from 'react-icons/lu'
import { PiDatabase } from 'react-icons/pi'
import { backend_url } from '../../utils/be'
import { useSelector } from 'react-redux'
import { selectUserState } from '../../redux/reducers/appStateReducer'
import ConfirmPurchase from '../modals/ConfirmPurchase'
import useEGYVault from '../../hooks/useEGYVault'
import { IonSpinner } from '@ionic/react'
import { motion } from "framer-motion"


const PurchaseEnergyCard = () => {
    const user = useSelector(selectUserState);
    const [purchaseEnergy, setPurchaseEnergy] = useState(false);
    const [transaction, setTransaction] = useState({});
    const { globalListings, vaultLoading } = useEGYVault();
    const [waitingColor, setWaitingColor] = useState("warning");
    const [selectedFilter, setSelectedFilter] = useState("");

    function purchase(_transaction: any) {
        setPurchaseEnergy(true);
        setTransaction(_transaction);
        console.log(_transaction);
    }

    function handleFilterSelection(filter: string) {
        setSelectedFilter(filter);
    }

    function applyFilter(listings: any[]) {
        switch (selectedFilter) {
            case "kwh_high_low":
                return listings.sort((a, b) => b.energy - a.energy);
            case "kwh_low_high":
                return listings.sort((a, b) => a.energy - b.energy);
            case "price_high_low":
                return listings.sort((a, b) => b.price - a.price);
            case "price_low_high":
                return listings.sort((a, b) => a.price - b.price);
            default:
                return listings;
        }
    }

    const filteredListings = applyFilter(globalListings || []);


    useEffect(() => {
        let t: any;

        const updateWaitingColor = () => {
            setWaitingColor((prevColor) => (prevColor === "warning" ? "success" : "warning"));
        };

        const startTimer = () => {
            t = setTimeout(() => {
                updateWaitingColor();
                startTimer();
            }, 5000);
        };

        if (vaultLoading) {
            t = setTimeout(() => {
                updateWaitingColor();
                startTimer();
            }, 3000);
        }

        return () => clearTimeout(t);
    }, [vaultLoading]);


    return (
        <div className="p-3 shadow rounded-lg">
            <div className="my-3 flex justify-between items-center font-medium text-xl">
                Market
                <div className='flex items-center gap-x-2'>
                    <Dropdown style={{ background: "transparent" }}>
                        <Dropdown.Toggle style={{ display: "flex", alignItems: "center", background: "transparent", outline: "none", border: "none", color: "black" }} name='toggle filter'>
                            <FiFilter className='' />
                        </Dropdown.Toggle>

                        <Dropdown.Menu style={{ border: "1px solid var(--ion-color-success)", fontSize: "13px", color: "gray" }}>
                            <Dropdown.Item style={{ padding: "3px 10px" }} onClick={() => handleFilterSelection("kwh_high_low")}>KWH high - low</Dropdown.Item>
                            <Dropdown.Item style={{ padding: "3px 10px" }} onClick={() => handleFilterSelection("kwh_low_high")}>KWH low - high</Dropdown.Item>
                            <Dropdown.Item style={{ padding: "3px 10px" }} onClick={() => handleFilterSelection("price_high_low")}>Price high - low</Dropdown.Item>
                            <Dropdown.Item style={{ padding: "3px 10px" }} onClick={() => handleFilterSelection("price_low_high")}>Price low - high</Dropdown.Item>
                        </Dropdown.Menu>

                    </Dropdown>

                </div>
            </div>
            <div className='border-b pb-2'>
                <span className="text-gray-500 text-sm ">Purchase Energy</span>
            </div>
            <section className='mt-4 h-[60vh] overflow-auto relative'>

                <div className='absolute top-[40%] left-1/2 text-center  -translate-x-1/2 -translate-y-1/2' >
                    <aside>{vaultLoading && <IonSpinner color={waitingColor} />} </aside>
                </div>
                {
                    filteredListings.map((item: { energy: string, price: number }, index: any) => {
                        return (
                            <div onClick={() => purchase(item)} key={index} className="border-[1px] mb-3 flex justify-between rounded-md border-gray-400 p-2 cursor-pointer">
                                <div className="flex gap-x-1 items-center">
                                    <HiOutlineLightningBolt className='text-primaryGreen' /> <span className='text-sm font-medium'>{item?.energy} KWh</span>
                                </div>
                                <div className="flex gap-x-1 items-center">
                                    <PiDatabase className='text-primaryGreen' /> <span className='text-sm font-medium'>{item?.price.toFixed(3)} TRG</span>
                                </div>
                                <div className="flex gap-x-1 items-center">
                                    <LuShoppingCart className='text-black text-lg' />
                                </div>
                            </div>
                        )
                    })
                }
            </section>
            <ConfirmPurchase onClose={() => setPurchaseEnergy(false)} open={purchaseEnergy} transaction={transaction} />
        </div>
    )
}


export default PurchaseEnergyCard