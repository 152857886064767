import axios from "axios";
import { IPData } from "../types/logs";


export function setupBearerToken() {
    try {
        const u = localStorage.getItem("user");
        if (u) {
            const user = JSON.parse(u);
            // console.log(user)
            axios.defaults.headers.common["Authorization"] = `Bearer ${user.token}`;
        }
    }
    catch (err) {
        console.log(err)
    }
}

export function setupAxiosHeader() {
    setupBearerToken();
    axios.defaults.headers.common["ip"] = localStorage.getItem("ip") || "";
    axios.defaults.headers.common["location"] = localStorage.getItem("location") || "";

    fetch("https://api.ipify.org/").then(async (res) => {
        const Ip = await res.text();
        axios.defaults.headers.common["ip"] = Ip;
        localStorage.setItem("ip", Ip);
    }).catch(console.log);


    fetch("https://ipapi.co/json/").then(async (res) => {
        const location = await res.json() as IPData;
        axios.defaults.headers.common["location"] = `${location.country_name}, ${location.city}`
        localStorage.setItem("location", `${location.country_name}, ${location.city}`);
    }).catch(console.log);

}