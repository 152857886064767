/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { IonSegment, IonSegmentButton } from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import useOrderBook from "../../hooks/useOrderBook";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { OrderBookContext } from "../../pages/NewTradePage";

const SellEnergyForm = () => {
  const { address } = useSelector((state: any) => state.twinergy);
  const { price: [mkPrice, setMkPrice] } = useContext(OrderBookContext)
  const { createSellOrder, fee } = useOrderBook();
  const [tabs, setTabs] = useState("0");
  const [loading, setLoading] = useState(false);
  const [price, setPrice] = useState<number>(0);
  const [totalPrice, setTotalPrice] = useState<number>(0);
  const [energyAmount, setEnergyAmount] = useState<number>(0);



  useEffect(() => {
    let _price = price;
    if (tabs === "0") _price = mkPrice;
    setTotalPrice(_price * energyAmount);
  }, [energyAmount]);



  const sellWatt = async () => {
    if (!address) {
      toast.error("Please connect your wallet")
      return
    }
    if (!energyAmount && !totalPrice) return;
    setLoading(true);
    const _price = tabs === "0" ? mkPrice : price;
    let result = await createSellOrder(energyAmount, totalPrice + fee, _price);
    if (result) {
      toast.success("Sell order created successfully!");
    } else {
      toast.error("Failed to confirm order!");
    }
    setLoading(false);
  };

  return (
    <div>
      <form action="" className="p-3 absolute bottom-10 w-100">
        <div className="mb-4 ">
          <IonSegment
            value={tabs}
            onIonChange={(e) => {
              setTabs(e.detail.value + "");
            }}
            color="warning"
          >
            <IonSegmentButton value={"0"}>Market</IonSegmentButton>
            <IonSegmentButton value={"1"}>Limit</IonSegmentButton>
          </IonSegment>
        </div>
        <div className="mb-2">
          <label>Price ( TRGY )</label>
          <input
            disabled={tabs === "0"}
            value={tabs === "0" ? mkPrice : price == 0 ? "" : price}
            onChange={(e) => setPrice(+e.target.value)}
            type="number"
            className={`p-2  border boder-black w-100 rounded outline-yellow-500 ${tabs === "0" ? "bg-gray-300" : "bg-gray-50"
              }`}
          />
        </div>
        <div className="mb-2">
          <label>Amount ( kWh )</label>
          <input
            type="number"
            value={energyAmount === 0 ? "" : energyAmount}
            onChange={(e) => setEnergyAmount(+e.target.value)}
            className="p-2 bg-gray-50 border boder-black w-100 rounded outline-yellow-500"
          />
        </div>
        <div className="h-9"></div>

        <section className="mt-9 mb-3 text-end">
          <div className="text-gray-500">
            <small> Fee (including network charges)</small> {fee} TRGY
          </div>
          <div className="text-gray-800 font-semibold mt-2 ">
            Total {Math.abs(totalPrice - fee)} TRGY
          </div>
        </section>
        <section>
          <button
            onClick={sellWatt}
            type="button"
            className="p-2 px-4 bg-yellow-500 w-100 text-white rounded"
          >
            {loading ? "Processing..." : "Sell WATT"}
          </button>
        </section>
      </form>
    </div>
  );
};

export default SellEnergyForm;
