/* eslint-disable react-hooks/exhaustive-deps */
import { ethers } from "ethers";
import { useEffect, useState } from "react";
import useConnector from "./useConnector";
import config from "../config";
import drr from "../contracts/token.json";
import { useSelector, useDispatch } from "react-redux";
import { updateDRRBal } from "../redux/reducers/twinergyReducer";
import { toast } from "react-toastify";

const useDRR = () => {
  const { address, DRRBalance } = useSelector(state => state.twinergy);
  const { provider } = useConnector();
  const [contract, setContract] = useState(null);
  const dispatch = useDispatch();
  const { location } = window

  useEffect(() => {
    if (provider) {
      const signer = provider.getSigner();
      setContract(
        new ethers.Contract(config.DRR, drr, signer)
      );
    }
  }, [provider, location.pathname]);

  useEffect(() => {
    if (address && !DRRBalance) {
      getBalance();
    }
  }, [location, address, provider]);
  const approve = async (_amount) => {
    try {
      const tx = await contract.approve(
        config.energy_vault,
        ethers.utils.parseUnits(String(_amount), "ether"),
        {
          gasLimit: 100000,
        }
      );
      await tx.wait();
      toast.success("Transaction Approved !");
      return true;
    } catch (error) {
      console.error(error);
      toast.error("Transaction Failed !");
      return false;
    }
  };
  const getBalance = async () => {
    const balance = await contract?.balanceOf(address);
    if (!Number(balance?._hex)) return dispatch(updateDRRBal("0.0"));
    // if (+balance?._hex) {
    dispatch(
      updateDRRBal(
        (Number(balance?._hex) / 10 ** 18)
      )
    );
    // }
  };
  return { approve };
};

export default useDRR;
