/* eslint-disable react-hooks/exhaustive-deps */
import { ethers } from "ethers";
import { useEffect, useState } from "react";
import useConnector from "./useConnector";
import config from "../config";
import fftm from "../contracts/fftm.json";
import { toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import {
  updateFTMBal
} from "../redux/reducers/twinergyReducer";

const useFTM = () => {
  const { address } = useSelector((state) => state.twinergy);
  const { provider } = useConnector();
  const [contract, setContract] = useState(null);
  const { location } = window

  const dispatch = useDispatch()
  useEffect(() => {
    if (provider) {
      const signer = provider.getSigner();
      setContract(new ethers.Contract(config.FFTM, fftm, signer));
    }
  }, [provider, location.pathname]);


  useEffect(() => {
    getBalance()
  }, [address, location.pathname]);

  const approve = async (_depositAmount) => {

    try {
      const tx = await contract.approve(
        config.trgy_vault,
        ethers.utils.parseUnits(String(_depositAmount), "ether"),
        {
          gasLimit: 100000,
        }
      );
      await tx.wait();
      toast.success("Transaction Approved !")
      return true;
    } catch (error) {
      console.error(error);
      toast.error("Transaction Failed !")
      return false;
    }
  };

  const getBalance = async () => {
    const balance = await contract?.balanceOf(address);
    if (!Number(balance?._hex)) return dispatch(updateFTMBal("0.0"))
    dispatch(updateFTMBal((Number(balance?._hex) / 10 ** 18).toLocaleString(
      undefined,
      { minimumFractionDigits: 2 }
    )))
  }
  return { approve };
}

export default useFTM
