import React, { useState } from "react";
import { Row, Card, Form, Button } from "react-bootstrap";
import "./../styles/walletpage.css";
import useFaucet from "../hooks/useFaucet";

export default function FaucetPage() {
  const [loading, setLoading] = useState(false);
  const { setAddress, _address, getFFTM } = useFaucet();

  const handlegetAsset = () => {
    if (loading) return;
    setLoading(true);
    getFFTM().then(() => {
      setLoading(false);
    });
  };
  const handleMouseEnter = (e: any) => {
    e.target.style.color = "#06152b";
  };
  const handleMouseLeave = (e: any) => {
    e.target.style.color = "white";
  };


  return (
    <div className="grid lg:grid-cols-2" style={{ padding: 30 }}>
      <div className="meter-card">
        <Card.Body>
          <Card.Title className="card-title">Testnet FFTM faucet</Card.Title>
          <div className="text-gray-500 font-light">
            Transactive energy module for FFTM tokens
          </div>

          <div className="bg-gray-500 h-[1px] my-4" />
          <Form>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <div style={{ position: "relative" }}>
                <Form.Label>Wallet address</Form.Label>
              </div>
              <Form.Control
                type="text"
                value={_address}
                onChange={e => {
                  setAddress(e.target.value!);
                }}
                className="text-in"
              />
              <Form.Text className="text-muted"></Form.Text>
            </Form.Group>
            {/* <Form.Group className="mb-3" controlId="formBasicEmail">
              <div style={{ position: "relative" }}>
                <Form.Label>Amount</Form.Label>
              </div>
              <Form.Control

                type="text"
                value={"0"}
                onChange={e => {
                  setAddress(e.target.value!);
                }}
                className="text-in outline-yellow-500"
              />
              <Form.Text className="text-muted"></Form.Text>
            </Form.Group> */}

            <Button
              style={{
                backgroundColor: "#7edd62",
                borderRadius: "10px",
                padding: "10px, 8px, 10px, 8px,",
                border: "none",
              }}
              className="submit-btn"
              onClick={handlegetAsset}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              {loading ? "Loading..." : "Send"}
            </Button>
          </Form>
        </Card.Body>
      </div>
    </div>
  );
}
