/* eslint-disable no-loop-func */
/* eslint-disable react-hooks/exhaustive-deps */
import { ethers } from "ethers";
import { useEffect, useState } from "react";
import useConnector from "./useConnector";
import config from "../config";
import energy_vault from "../contracts/energy_vault.json";
import axios from "axios";
import { useSelector } from "react-redux";
import useMeters from "./useMeters";

const useEGYVault = () => {
  const { provider } = useConnector();
  const [contract, setContract] = useState(null);
  const [listings, setListings] = useState([]);
  const [globalListings, setGlobalListings] = useState([]);
  const { address } = useSelector((state) => state.twinergy);
  const [loading, setLoading] = useState(false);
  const location = window.location;
  const { batMeterAddress } = useMeters();

  useEffect(() => {
    if (provider) {
      const signer = provider.getSigner();
      setContract(
        new ethers.Contract(config.energy_vault, energy_vault, signer)
      );
    }
  }, [provider, location.pathname]);

  useEffect(() => {
    address && getListings();
  }, [contract, location.pathname, address]);

  async function getListings() {
    setLoading(true);
    await contract
      ?.listCount()
      .then(async (_data) => {
        let arrayLength = +_data._hex;
        let uListings = [];
        for (let i = 0; i < arrayLength; i++) {
          await contract
            ?.listings(i)
            .then((data) => {
              uListings = [
                ...uListings,
                {
                  energy: +data[0]._hex / 10 ** 18,
                  price: +data[1]._hex / 10 ** 18,
                  seller: data[2],
                  index: i,
                },
              ];
            })
            .catch((err) => {
              console.error(err);
            });
        }
        setListings(
          uListings.filter(
            (item) => item.seller.toLowerCase() === address.toLowerCase()
          )
        );
        setGlobalListings(
          uListings.filter(
            (item) => item.seller.toLowerCase() !== address.toLowerCase()
          )
        );
      })
      .catch((err) => console.log(err));
    setLoading(false);
  }

  const mintDRR = async (amount) => {
    try {
      const txn = await contract?.mintDRR(
        ethers.utils.parseUnits(String(amount), "ether")
      );
      await txn.wait();
      return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  const burnDRR = async (amount) => {
    try {
      const txn = await contract?.burnDRR(
        ethers.utils.parseUnits(String(amount), "ether")
      );
      await txn.wait();
      return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  const createListing = async (amount, location, onStatusChange) => {
    try {
      onStatusChange("Calculating Price ...")
      const response = await axios.post(
        "https://lem.dev.twinergy.transactiveenergymodule.com/api/v1/price/price_calc",
        {
          energyAmount: amount,
          user: address,
          location: location,
        }
      );
      if (response.data.success) {
        onStatusChange("Creating Listing ...")
        const txn = await contract?.createListing(
          ethers.utils.parseUnits(String(amount), "ether")
        );
        onStatusChange("Waiting for Confirmation ...")
        await txn.wait();
        onStatusChange("")
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  const cancelListing = async (index) => {
    console.log(index);

    try {
      const txn = await contract?.cancelListing(index);
      await txn.wait();
      console.log(txn);
      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  const buyEnergy = async (seller, index, onChainTxnId) => {
    const txn = await contract?.buyEnergy(
      seller,
      index,
      batMeterAddress,
      onChainTxnId
    );
    await txn.wait();
    return txn;
  };

  const cancelBuyEnergy = async (seller, price, energyAmount) => {
    const txn = await contract?.cancelBuy(
      seller,
      ethers.utils.parseUnits(String(price), "ether"),
      ethers.utils.parseUnits(String(energyAmount), "ether")
    );
    await txn.wait();
    return txn;
  };

  return {
    mintDRR,
    burnDRR,
    createListing,
    cancelListing,
    buyEnergy,
    globalListings,
    listings,
    getListings,
    vaultLoading: loading,
    cancelBuyEnergy,
  };
};

export default useEGYVault;
