import { useState } from "react";
import { Button, Image } from "react-bootstrap";
import "../layout/nav.css";
import useConnector from "../../hooks/useConnector";
import { useSelector } from "react-redux";
import styles from "../layout/nav.module.css";
import millify from "millify";
import Hagedorn from "./../../asserts/images/Flags.svg";
import { RootState } from "../../redux/store";


const WalletConnect = () => {
    const { connectWallet } = useConnector();
    const { address } = useSelector((state: RootState) => state.twinergy);
    const { TRGYBalance } = useSelector((state: RootState) => state.twinergy);
    const [username, setUsername] = useState("");
    const [pilotsite, setPilotsite] = useState("");
    const [image, setImage] = useState(Hagedorn);


    return (
        <>
            {address ? (
                <div className={styles.wyellow}>
                    <span
                        className={styles.waddres}
                        style={{ fontSize: "10px" }}
                    >{`${address.slice(0, 7)}...${address.slice(38, 42)}`}</span>
                    <div className={styles.wgreen}>
                        <Image
                            src={require("./../../asserts/icons/sym.svg").default}
                            className={styles.wicon}
                        />
                        <span className={styles.wbalance}>
                            {millify(Number(TRGYBalance), {
                                units: ["", "K", "M", "B", "T", "P", "E"],
                                space: false,
                            })}{" "}
                            TRGY
                        </span>
                    </div>
                </div>
            ) : (
                <div>
                    <Button
                        className="btn"
                        size="sm"
                        style={{ backgroundColor: "#7edd62", border: "none" }}
                        onClick={() => connectWallet()}
                    >
                        Connect Wallet
                    </Button>
                </div>
            )}
        </>
    )
}

export default WalletConnect