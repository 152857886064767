import axios from "axios";
import { backend_url } from "./be";
import redstone from "redstone-api";


const getSettings = async () => {
    const result = await axios.get(`${backend_url}/user/trade/settings`);
    return result.data[0];
}

const calculatePriceInTRGY = async (price: number) => {
    // Get rates from API Layer
    if (price) {
        let { rate } = await getSettings();

        // price from red stone
        const priceFTMUSD = await redstone.getPrice("FTM");
        let FTMEUR = rate * priceFTMUSD.value;
        return (+price / FTMEUR)  // PRICE in FTM TWINGERY
    }
};

export { calculatePriceInTRGY };