import React from "react";
import { Row, Card, Image } from "react-bootstrap";
import "./../styles/walletpage.css";
import styles from "./../styles/wallet.module.css";
import useMeters from "../hooks/useMeters";
import { RootState } from "../redux/store";
import { useSelector } from "react-redux";

export default function ReadingsPage(): JSX.Element {
  const {
    mainIMeterReading,
    mainOMeterReading,
    pvIMeterReading,
    pvOMeterReading,
    battMainMeterReading,
    battITranMeterReading,
    battOTranMeterReading,
    pvMeterAddress,
    mainMeterAddress,
    batMainMeterAddress,
    batMeterAddress,
  } = useMeters();
  const { WATTBalance } = useSelector((state: RootState) => state.twinergy);

  return (
    <React.Fragment>
      <Row style={{ padding: 30 }}>
        <div className="grid lg:grid-cols-2 gap-3">
          <div className={"meter-card"}>
            <Card.Body>
              <Card.Title className={styles.card_title}>
                Main Usage{" "}
                <a
                  href={`https://testnet.ftmscan.com/address/${mainMeterAddress}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <Image
                    src={require("./../asserts/icons/arrowicon.svg").default}
                    className="icon"
                  />
                </a>
              </Card.Title>
              <Card.Subtitle className={styles.card_subtitle}>
                Main electricity usage from the grid
              </Card.Subtitle>
              <hr />
              <div className={styles.disp}>
                <Image
                  src={require("./../asserts/icons/sym2.svg").default}
                  className={styles.dispicon}
                />
                <div className={styles.dispval}>{mainIMeterReading}</div>
              </div>
            </Card.Body>
          </div>
          <div className={"meter-card"}>
            <Card.Body>
              <Card.Title className={styles.card_title}>
                Main delivery
                <a
                  href={`https://testnet.ftmscan.com/address/${mainMeterAddress}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <Image
                    src={require("./../asserts/icons/arrowicon.svg").default}
                    className="icon"
                  />
                </a>
              </Card.Title>
              <Card.Subtitle className={styles.card_subtitle}>
                Main electric delivery to the grid
              </Card.Subtitle>
              <hr />
              <div className={styles.disp}>
                <Image
                  src={require("./../asserts/icons/sym2.svg").default}
                  className={styles.dispicon}
                />
                <div className={styles.dispval}>{+(mainOMeterReading || 0) / 1000}</div>
              </div>
            </Card.Body>
          </div>
          <div className={"meter-card"}>
            <Card.Body>
              <Card.Title className={styles.card_title}>
                PV generation
                <a
                  href={`https://testnet.ftmscan.com/address/${pvMeterAddress}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <Image
                    src={require("./../asserts/icons/arrowicon.svg").default}
                    className="icon"
                  />
                </a>
              </Card.Title>
              <Card.Subtitle className={styles.card_subtitle}>
                Measuring kWh generation
              </Card.Subtitle>
              <hr />
              <div className={styles.disp}>
                <Image
                  src={require("./../asserts/icons/sym2.svg").default}
                  className={styles.dispicon}
                />
                <div className={styles.dispval}>{Number(pvIMeterReading) / 1000}</div>
              </div>
            </Card.Body>
          </div>
          <div className={"meter-card"}>
            <Card.Body>
              <Card.Title className={styles.card_title}>
                Battery charge
                <a
                  href={`https://testnet.ftmscan.com/address/${pvMeterAddress}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <Image
                    src={require("./../asserts/icons/arrowicon.svg").default}
                    className="icon"
                  />
                </a>
              </Card.Title>
              <Card.Subtitle className={styles.card_subtitle}>
                Stored pv generated energy
              </Card.Subtitle>
              <hr />
              <div className={styles.disp}>
                <Image
                  src={require("./../asserts/icons/sym2.svg").default}
                  className={styles.dispicon}
                />
                <div className={styles.dispval}>{Number(pvOMeterReading) / 1000}</div>
              </div>
            </Card.Body>
          </div>
          <div className={"meter-card"}>
            <Card.Body>
              <Card.Title className={styles.card_title}>
                Battery discharge
                <a
                  href={`https://testnet.ftmscan.com/address/${batMainMeterAddress}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <Image
                    src={require("./../asserts/icons/arrowicon.svg").default}
                    className="icon"
                  />
                </a>
              </Card.Title>
              <Card.Subtitle className={styles.card_subtitle}>
                Consumption of stored energy
              </Card.Subtitle>
              <hr />
              <div className={styles.disp}>
                <Image
                  src={require("./../asserts/icons/sym2.svg").default}
                  className={styles.dispicon}
                />
                <div className={styles.dispval}>{Number(battMainMeterReading) / 1000}</div>
              </div>
            </Card.Body>
          </div>
          <div className={"meter-card"}>
            <Card.Body>
              <Card.Title className={styles.card_title}>
                Battery state of charge
                <a
                  href={`https://testnet.ftmscan.com/address/${batMainMeterAddress}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <Image
                    src={require("./../asserts/icons/arrowicon.svg").default}
                    className="icon"
                  />
                </a>
              </Card.Title>
              <Card.Subtitle className={styles.card_subtitle}>
                Current volume of stored kWh
              </Card.Subtitle>
              <hr />
              <div className={styles.disp}>
                <Image
                  src={require("./../asserts/icons/sym2.svg").default}
                  className={styles.dispicon}
                />
                <div className={styles.dispval}>{WATTBalance}</div>
              </div>
            </Card.Body>
          </div>
          <div className={"meter-card"}>
            <Card.Body>
              <Card.Title className={styles.card_title}>
                Battery transact (buy)
                <a
                  href={`https://testnet.ftmscan.com/address/${batMeterAddress}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <Image
                    src={require("./../asserts/icons/arrowicon.svg").default}
                    className="icon"
                  />
                </a>
              </Card.Title>
              <Card.Subtitle className={styles.card_subtitle}>
                Purchase of energy from the LEM
              </Card.Subtitle>
              <hr />
              <div className={styles.disp}>
                <Image
                  src={require("./../asserts/icons/sym2.svg").default}
                  className={styles.dispicon}
                />
                <div className={styles.dispval}>{+(battITranMeterReading || 0) / 1000}</div>
              </div>
            </Card.Body>
          </div>
          <div className={"meter-card"}>
            <Card.Body>
              <Card.Title className={styles.card_title}>
                Battery transact (sell)
                <a
                  href={`https://testnet.ftmscan.com/address/${batMeterAddress}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <Image
                    src={require("./../asserts/icons/arrowicon.svg").default}
                    className="icon"
                  />
                </a>
              </Card.Title>
              <Card.Subtitle className={styles.card_subtitle}>
                Sale of stored energy to the LEM
              </Card.Subtitle>
              <hr />
              <div className={styles.disp}>
                <Image
                  src={require("./../asserts/icons/sym2.svg").default}
                  className={styles.dispicon}
                />
                <div className={styles.dispval}>{(+(battOTranMeterReading || 0)) / 1000}</div>
              </div>
            </Card.Body>
          </div>
        </div>
      </Row>
    </React.Fragment>
  );
}
