import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import axios, { AxiosHeaders } from "axios";
import { toast } from "react-toastify";
import {
  Form,
  Button,
  FormCheck,
  Row,
  Col,
  Card,
} from "react-bootstrap";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { backend_url } from "../utils/be";
import { IonCheckbox, IonSpinner, useIonToast } from "@ionic/react";
import footerImage from "../asserts/images/footer_flag.svg";
import "./../styles/login.css";
import jwt from "jwt-decode";
import { endpoint } from "../utils/endpoint";
import GoogleAuthButton from "../components/auth/GoogleAuthButton";


export default function SignupPage() {
  let navigate = useNavigate();

  const [email, setemail] = useState("");
  const [username, setusername] = useState("");
  const [pass, setpass] = useState("");
  const [showPassword, setShowPassword] = useState(false)
  const [loading, setLoading] = useState(false)
  const [toastAlert] = useIonToast()


  const signup = async (e: any) => {
    e.preventDefault();

    const user = {
      name: username,
      email,
      password: pass
    }
    setLoading(true)

    await axios.post(`${backend_url}/user/signup`, user).then(res => {
      console.log(res.data)
      toastAlert({ message: res.data.message || JSON.stringify(res.data), buttons: [{ text: "Ok" }], color: "warning", position: "bottom" })
    }).catch(err => {
      console.log(err)
      toastAlert({ message: "An error occured while logging in", color: "danger", buttons: ["ok"] })

    })


    setLoading(false)


  };


  return (
    <React.Fragment>
      <Row className="col-container">
        <Col className="left-col " sm="12" md="6">
          <div>
            <h1 className="">Sign Up</h1>
            <p className="text-gray-500 font-light">Fill the following info to Sign up! <span className="w-20"></span></p>
            <form onSubmit={(e) => signup(e)} className="text-left ">
              <div className="text-black font-light mb-3">
                <label className="text-[14px]">Username *</label>
                <input
                  required
                  value={username}
                  onChange={(e) => setusername(e.target.value)}
                  placeholder="e.g Johny" className={styles["login-input"]} type="text" />
              </div>
              <div className="text-black font-light mb-3">
                <label className="text-[14px]">Email*</label>
                <input
                  value={email}
                  required
                  onChange={(e) => setemail(e.target.value)}
                  placeholder="mail@simmple.com" className={styles["login-input"]} type="email" />
              </div>
              {/* <div className="text-black font-light mb-3">
                <label className="text-[14px]">Country</label>
                <input
                  value={email}
                  required
                  onChange={(e) => setemail(e.target.value)}
                  placeholder="mail@simmple.com" className={styles["login-input"]} type="email" />
              </div> */}
              <div className="text-black font-light mb-3">
                <label className="text-[14px]">Password*</label>
                <div className="relative">
                  <input
                    type={showPassword ? "text" : "password"}
                    value={pass}
                    required
                    onChange={(e) => setpass(e.target.value)}
                    placeholder="Min. 8 characters" className={styles["login-input"]}
                  />
                  <span onClick={() => setShowPassword(!showPassword)} className="absolute right-3 text-gray-600 top-1/3">
                    {!showPassword ? <AiOutlineEye /> :
                      <AiOutlineEyeInvisible />}
                  </span>
                </div>

              </div>
              <div className="md:grid grid-cols-2 font-light text-sm ">
                <div className="items-center flex">
                  <IonCheckbox color={"warning"} style={{ "--border-color": "var(--ion-color-warning)" }} />
                  <span className="ml-1">Keep me logged in</span>
                </div>
                <div>
                  <Link to={'/signin'} className="text-primaryGreen hover:text-primaryGreen float-right">Login instead</Link>
                </div>
              </div>
              <div className="mt-10 ">
                <button type="submit" className="p-3 w-full bg-[#7EDD62] rounded-xl text-white md:text-sm" name="sign in button">
                  {!loading ? "Sign In" : <IonSpinner color={"dark"} />}
                </button>
              </div>
              {/* <div className="mt-10 font-light text-sm">
                <span>Create an Account?</span>
                <Link className="text-[#7EDD62] ml-1 hover:text-[#7EDD62] font-normal" to="https://twinergy_citizens.tec.etra-id.com/register">Create an Account</Link>
              </div> */}


            </form>
            <div className="text-center my-6 mt-14">OR</div>

            <div className="oauth p-2 ">
              <GoogleAuthButton />
            </div>
          </div>
        </Col>
        <Col className="right-col relative">
          <Card
            style={{ width: "18rem", border: 0, boxShadow: "none" }}
            className="right-card"
          >
            <Card.Img
              variant="top"
              src={require("./../asserts/images/logo.svg").default}
            />
            <Card.Text className="right-text">
              Transactive Energy Module
            </Card.Text>
          </Card>
          <div className="absolute bottom-0 text-left w-full px-4 lg:px-10 py-4 text-[#8F9BBA] items-center text-xs flex">
            <img src={footerImage} className="w-14 rounded-sm" alt="signup image" />
            <div className="mx-2">
              Twinergy has received funding from the European Union's Horizon 2020 Research and Innovation Programme under Grant Agreement No. 957736
            </div>
          </div>
        </Col>
      </Row>
    </React.Fragment>
  );
}

const styles = {
  "login-input": "inline-block w-full placeholder:font-light placeholder:text-gray-600 border-gray-600 border-[0.5px] rounded-xl p-2 pl-3 outline-yellow-500"
}
