import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axios, { AxiosHeaders } from "axios";
import { toast } from "react-toastify";
import {
  Form,
  Button,
  FormCheck,
  Row,
  Col,
  Card,
} from "react-bootstrap";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { backend_url } from "../utils/be";
import { IonCheckbox, IonSpinner, useIonToast } from "@ionic/react";
import footerImage from "../asserts/images/footer_flag.svg";
import "./../styles/login.css";
import jwt from "jwt-decode";
import { endpoint } from "../utils/endpoint";
import { AuthService, useAuthAPI } from "../api/auth.api";
import GoogleAuthButton from "../components/auth/GoogleAuthButton";
import { selectUserState, setUserState } from "../redux/reducers/appStateReducer";

export default function LoginPage() {
  let navigate = useNavigate();

  const [email, setemail] = useState("");
  const [pass, setpass] = useState("");
  const [showPassword, setShowPassword] = useState(false)
  const [loading, setLoading] = useState(false)
  const [toast] = useIonToast();
  const user = useSelector(selectUserState)
  const dispatch = useDispatch()
  const { getUserData } = useAuthAPI()


  const signin = async (e: any) => {
    e.preventDefault();
    const headers = {
      "ngrok-skip-browser-warning": true,
    }

    // minimum of 12 characters for the password
    if (pass.length < 12) {
      toast({ message: "Password must be at least 12 characters", buttons: [{ text: "Ok" }], color: "danger", position: "bottom", duration: 3000 });
      return;
    }

    setLoading(true)




    await axios
      .post(`${backend_url}/user/login`, {
        email,
        password: pass,
      }, { headers })
      .then(async (res) => {
        console.log(res.data)

        if (res.data.success) {

          if (res.data.fa_auth) {
            navigate("/verify-otp", { replace: true, state: { email } })
            return;
          }

          const response = await getUserData(res.data.id as string, res.data.token as string);


          if (response.data.success) {
            window.location.href = "/";
            return

          }
        }


        else {
          toast({ message: res.data.message, buttons: [{ text: "Ok" }], color: "danger", position: "bottom" });
        }

      })
      .catch((err) => {
        console.error(err);
        toast({ message: "An error occured while logging in", color: "danger", buttons: ["ok"] })

      });

    setLoading(false)

  };



  return (
    <React.Fragment>
      <Row className="col-container">
        <Col className="left-col " sm="12" md="6">
          <div>
            <h1 className="">Sign In</h1>
            <p className="text-gray-500 font-light">Enter your username and password to sign in!</p>
            <form onSubmit={(e) => signin(e)} className="text-left ">
              <div className="text-black font-light mb-3">
                <label className="text-[14px]">Username/Email*</label>
                <input
                  value={email}
                  onChange={(e) => setemail(e.target.value)}
                  placeholder="mail@simmple.com" className={styles["login-input"]} type="text" />
              </div>
              <div className="text-black font-light mb-3">
                <label className="text-[14px]">Password*</label>
                <div className="relative">
                  <input
                    type={showPassword ? "text" : "password"}
                    value={pass}
                    onChange={(e) => setpass(e.target.value)}
                    placeholder="Min. 12 characters" className={styles["login-input"]}
                  />
                  <span onClick={() => setShowPassword(!showPassword)} className="absolute right-3 text-gray-600 top-1/3">
                    {!showPassword ? <AiOutlineEye /> :
                      <AiOutlineEyeInvisible />}
                  </span>
                </div>

              </div>
              <div className="md:grid grid-cols-2 font-light text-sm ">
                <div className="items-center flex">
                  <IonCheckbox color={"warning"} style={{ "--border-color": "var(--ion-color-warning)" }} />
                  <span className="ml-1">Keep me logged in</span>
                </div>
                <div>
                  <Link to={'/signup'} className="text-primaryGreen hover:text-primaryGreen">Don't have an account?</Link>
                </div>
              </div>
              <div className="mt-10 ">
                <button type="submit" className="p-3 w-full bg-[#7EDD62] rounded-xl text-white text-sm" name="sign in button">
                  {!loading ? "Sign In" : <IonSpinner color={"dark"} />}
                </button>
              </div>
              {/* <div className="mt-10 font-light text-sm">
                <span>Create an Account?</span>
                <Link className="text-[#7EDD62] ml-1 hover:text-[#7EDD62] font-normal" to="https://twinergy_citizens.tec.etra-id.com/register">Create an Account</Link>
              </div> */}


            </form>
            <div className="text-center my-6 mt-14">OR</div>

            <div className="oauth p-2 ">

              <div>
                <GoogleAuthButton />
              </div>
            </div>
          </div>
        </Col>
        <Col className="right-col relative">
          <Card
            style={{ width: "18rem", border: 0, boxShadow: "none" }}
            className="right-card"
          >
            <Card.Img
              variant="top"
              src={require("./../asserts/images/logo.svg").default}
            />
            <Card.Text className="right-text">
              Transactive Energy Module
            </Card.Text>
          </Card>
          <div className="absolute bottom-0 text-left w-full px-4 lg:px-10 py-4 text-[#8F9BBA] items-center text-xs flex">
            <img src={footerImage} className="w-14 rounded-sm" alt="footer image" />
            <div className="mx-2">
              Twinergy has received funding from the European Union's Horizon 2020 Research and Innovation Programme under Grant Agreement No. 957736
            </div>
          </div>
        </Col>
      </Row>
    </React.Fragment>
  );
}

const styles = {
  "login-input": "inline-block w-full placeholder:font-light placeholder:text-gray-600 border-gray-600 border-[0.5px] rounded-xl p-2 pl-3 outline-yellow-500"
}
