import { Dropdown } from "react-bootstrap";
import "../layout/nav.css";
import styles from "../layout/nav.module.css";
import { useNavigate } from "react-router-dom";
import { BsGear } from "react-icons/bs"
import { IoExitOutline, IoLogOutOutline, IoSettingsOutline } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { clearUserState } from "../../redux/reducers/appStateReducer";
import { IonApp, IonButton, IonContent, IonIcon, IonItem, IonLabel, IonPopover, IonSelect, IonSelectOption } from "@ionic/react";
import { RootState } from "../../redux/store";
import AlertModal from "../AlertModal";
import { useState } from "react";


const SideBarDropDown = () => {
    let navigate = useNavigate();
    const { address } = useSelector((state: RootState) => state.twinergy);
    const dispatch = useDispatch()
    const [walletAlert, setWalletAlert] = useState(false)

    function logout() {
        if (address) {
            setWalletAlert(true)
        } else {
            localStorage.clear();
            navigate("/");
            window.location.reload();
        }
    }

    return (
        <>

            <>
                <button>
                    <BsGear id="menu-trigger" className="gear" />
                </button>
            </>
            <IonPopover dismissOnSelect trigger="menu-trigger">
                <IonContent color='dark'>
                    <IonItem className="hover:text-primaryGreen" onClick={() => { logout(); }} color={'none'} button>
                        <span slot="start" className="mr-2">
                            <IoExitOutline />
                        </span>
                        <IonLabel>Log out</IonLabel>
                    </IonItem>
                </IonContent>
            </IonPopover>
            <AlertModal message={"Please disconnect your wallet before logging out"} title="Disconnect Wallet" setShow={() => { setWalletAlert(false) }} show={walletAlert} />

        </>
    )
}

export default SideBarDropDown