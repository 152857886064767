import { configureStore } from "@reduxjs/toolkit";
import twinergyReducer from "./reducers/twinergyReducer";
import appStateReducer from "./reducers/appStateReducer";
import DRRReducer from "./reducers/DERReducer";

const store = configureStore({
  reducer: {
    twinergy: twinergyReducer,
    appState: appStateReducer,
    DRRReward: DRRReducer
  },
});

export type RootState = ReturnType<typeof store.getState>;

export default store;