import React, { useState } from "react";
import { ISellOrder, IBuyOrder } from "../../../types/trade";
import ConfirmModal from "../../ConfirmModal";
import useOrderBook from "../../../hooks/useOrderBook";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";

interface Props {
  order: ISellOrder | IBuyOrder;
}

const MyOrderItem: React.FC<Props> = ({ order }) => {
  const { fee, cancelBuyOrder, cancelSellOrder } = useOrderBook();
  const [cancelOrder, setCancelOrder] = useState(false);
  const [loading, setLoading] = useState(false);
  const { address } = useSelector((state: RootState) => state.twinergy);




  async function deleteOrder() {
    try {
      setLoading(true);
      if ((order as ISellOrder).seller) {
        await cancelSellOrder(order?.index);
      } else {
        await cancelBuyOrder(order?.index, (order as IBuyOrder).partFill);
      }
      setLoading(false);
      toast.success("Order deleted successfully");
    } catch (error) {
      toast.error("Error deleting");
      console.error(error);
      setLoading(false);
    }
  }


  if (order.energyAmount === 0) return <></>;

  return (
    <>
      <section
        onClick={() => setCancelOrder(true)}
        className="grid grid-cols-3 text-sm text-gray-500 cursor-pointer hover:bg-gray-50"
      >
        <div
          className={
            (order as ISellOrder).seller
              ? "text-yellow-600"
              : "text-primaryGreen"
          }
        >
          {(order as ISellOrder).seller
            ? (Math.abs(order?.total - fee) / order.energyAmount).toFixed(2)
            : (order?.total / order.energyAmount).toFixed(2)}
        </div>
        <div>{order.energyAmount.toFixed(2)}</div>
        <div>
          {(order as ISellOrder).seller
            ? Math.abs(order.total - fee).toFixed(2)
            : order.total.toFixed(2)}
        </div>
      </section>

      <ConfirmModal
        loading={loading}
        handleClose={(res) => {
          if (res) deleteOrder();
          setCancelOrder(false);
        }}
        message="Are  you sure you want to delete this order ?"
        title="Delete Order"
        show={cancelOrder}
      />
    </>
  );
};

export default MyOrderItem;
