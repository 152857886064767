import React, { useContext, useEffect, useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import {
    AreaChart,
    Area,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
} from "recharts";
import styles from "./../styles/home.module.css";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { IonFab, IonFabButton, IonSegment, IonSegmentButton, IonSpinner, IonToolbar } from "@ionic/react";
import { IoAdd, IoChevronBack, IoChevronForward } from "react-icons/io5";
import { motion, AnimatePresence } from "framer-motion"
import { selectUserState } from "../../redux/reducers/appStateReducer";
import { updateLemPrice } from "../../redux/reducers/twinergyReducer";
import useOrderBook from "../../hooks/useOrderBook";
import { OrderBookContext } from "../../pages/NewTradePage";
import { calculatePriceInTRGY } from "../../utils/trade";

interface DataPoint {
    name: string;
    Projected_price: number;
    Curent_price: number;
}


interface LemPriceData {
    id: number;
    timestamp: string;
    hour_index: string;
    bristol: string;
    hagedorn: string;
    benetutti: string;
}

const TradeLemGraph: React.FC = () => {
    const dispatch = useDispatch()
    const { spread } = useOrderBook()
    const userInfo = useSelector(selectUserState)
    const [data, setData] = useState<LemPriceData[]>([]);
    const [loading, setLoading] = useState(false)
    const [filterValue, setFilterValue] = useState("day")
    // const city = +(userInfo?.pilotId || 0) == 1 ? "bristol" : +(userInfo?.pilotId || 0) == 2 ? "hagedorn" : "benetutti"
    const graphRef = useRef<HTMLElement>(null)
    const [scrolledEnd, setScrolledEnd] = useState(false)
    const { price: [pricePerKw] } = useContext(OrderBookContext)

    useEffect(() => {
        getLemPriceFilter(filterValue);
    }, [filterValue])

    function getLemPriceFilter(filter: string) {
        const backend_url = `https://lem.dev.twinergy.transactiveenergymodule.com/api/v1/lem/lem_price/filter/${filter}`
        const newLocal = `${backend_url}`;
        setLoading(true);

        if (filter === "day") {
            getLemPriceToday()
            return
        }

        axios.get(newLocal)
            .then(async function (response) {
                if (response.data.success) {

                    console.log(response.data.data);
                    const prices = (response.data.data) as LemPriceData[]
                    let result = prices.reverse().map((p) => ({ ...p, dayHour: p.timestamp.split("/")[0] + "th/" + p.hour_index + " hr" }))
                    if (filter == "month") {
                        result = await Promise.all(
                            result.map(async (p, i) => {
                                const bristol = (await calculatePriceInTRGY(+p.bristol) || 0).toFixed(3);
                                return { ...p, dayHour: `${p.hour_index}`, otherIndex: `${p.timestamp.split("/")[0]}/${+p.timestamp.split("/")[1]}`, bristol }
                            })
                        )
                    }
                    else if (filter == "week") {
                        result = await Promise.all(result.map(async (p, i) => {
                            const bristol = (await calculatePriceInTRGY(+p.bristol) || 0).toFixed(3);
                            return { ...p, dayHour: `${p.hour_index}`, otherIndex: `${p.timestamp.split("/")[0]}/${+p.timestamp.split("/")[1]}`, bristol, }

                        }))
                    }
                    setData(result)
                    console.log(result)
                } else {
                    console.log("error")
                }
                setLoading(false)

            }
            )
            .catch(function (error) {
                console.log(error);
                setLoading(false)

            }
            )
    }

    function getLemPriceToday() {
        const backend_url = "https://lem.dev.twinergy.transactiveenergymodule.com/api/v1/lem"
        const newLocal = `${backend_url}/all_lem_price`;
        setLoading(true);

        axios.get(newLocal)
            .then(async function (response) {
                if (response.data.success) {
                    const prices = (response.data.data) as LemPriceData[]
                    dispatch(updateLemPrice(prices[0]))
                    const data = prices.filter((price, index) => {
                        //if day not today
                        if (index >= prices.length - 24) return true;
                        return false;
                    }).map(async (p) => {
                        const bristol = (await calculatePriceInTRGY(+p.bristol) || 0).toFixed(3);
                        return { ...p, dayHour: `${p.hour_index}`, otherIndex: `${p.timestamp.split("/")[0]}/${+p.timestamp.split("/")[1]}`, bristol }
                    })
                        .reverse()
                    const results = await Promise.all(data);
                    setData(results);
                    // console.log(response.data.data)
                } else {
                    console.log("error")
                }
                setLoading(false)

            }
            )
            .catch(function (error) {
                console.log(error);
                setLoading(false)

            }
            )

    }

    return (
        <div>
            <section className="grid grid-cols-3 px-6 items-center text-center">
                <div className="font-semibold text-start">
                    Price/kWh : {pricePerKw.toFixed(5)}
                </div>
                <div className="p-2 " >
                    <IonSegment className="m-auto" mode="ios" value={filterValue} onIonChange={(e) => { setFilterValue(e.detail.value + "") }}>
                        <IonSegmentButton value={"day"}>Today</IonSegmentButton>
                        <IonSegmentButton value={"week"}>Last week</IonSegmentButton>
                        <IonSegmentButton value={"month"}>Last month</IonSegmentButton>
                    </IonSegment>
                </div>
                <div className="font-semibold text-primaryGreen text-end">
                    Spread : {spread}
                </div>
            </section>
            <Row className="overflow-hidden w-100">
                <Col
                    ref={graphRef as any} style={{ position: "relative", width: "100%" }}>
                    {loading && <IonSpinner className="absolute top-6" />}
                    <AreaChart
                        width={window.innerWidth - window.innerWidth / 100 * 55}
                        height={400}
                        data={data}
                        style={{ scrollbarWidth: "thin" }}
                        className="text-sm "

                        margin={{
                            top: 10,
                            right: 30,
                            left: 0,
                            bottom: 0,
                        }}
                    >
                        <CartesianGrid strokeDasharray="2 2" />
                        <XAxis xAxisId="0" dataKey="dayHour" style={{ fontSize: "12px", }} />
                        {filterValue != "day" && <XAxis xAxisId="1" style={{ fontSize: "10px" }} dataKey="otherIndex" allowDuplicatedCategory={false} />}
                        <YAxis dataKey={"bristol"} domain={[0, 2]} />
                        <Tooltip />
                        <Area
                            type="monotone"
                            dataKey={"bristol"}
                            stackId="1"
                            stroke="#58b4ff"
                            fill="#58b4ff"
                        />
                    </AreaChart>
                    {/* {!scrolledEnd && <IonFab onClick={() => { graphRef.current?.scroll({ left: 1000, behavior: "smooth" }); setScrolledEnd(true) }} vertical="center" horizontal="end">
                        <IonFabButton className="" mode="ios" color="light" size="small">
                            <IoChevronForward className="text-xl" />
                        </IonFabButton>
                    </IonFab>} */}
                </Col>
            </Row>
        </div>
    );
};

export default TradeLemGraph;

