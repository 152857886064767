import axios, { AxiosResponse } from "axios";
import { backend_url } from "../utils/be";
import { endpoint } from "../utils/endpoint";
import { GoogleAuthProvider, getAuth, signInWithPopup, signInWithRedirect } from "firebase/auth";
import { app, auth } from "./firebase";
import { setUserState } from "../redux/reducers/appStateReducer";
import { useIonToast } from "@ionic/react";
import { useDispatch } from "react-redux";

//AuthServiceResponse
export interface AuthServiceResponse {
    message: string;
    success: boolean;
    data?: any;
}


export class AuthService {


    static async googleSignin() {

        const googleProvider = new GoogleAuthProvider()
        const auth = getAuth(app);
        return signInWithPopup(auth, googleProvider)
            .then(async (result) => {
                const credential = GoogleAuthProvider.credentialFromResult(result);
                // const token = credential?.accessToken;
                console.log(credential);

                const token = await auth.currentUser?.getIdToken()
                // console.log(token);

                return axios.get(`${backend_url}/user/google_signin/${token}`)



            }).catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                // const email = error.customData.email;
                // const credential = GoogleAuthProvider.credentialFromError(error);
                console.log(errorMessage)
                return error
            });

    }


}


export function useAuthAPI() {

    const [toast] = useIonToast();
    const dispatch = useDispatch()

    async function getUserData(id: string, token: string) {
        try {
            const response = await axios.get(`${backend_url}/user/${id}`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
            if (response.data.success) {
                const { country, dwelling_id, email, id, name, family_name, iat, id_number, identificationNumber, language, phone, pilotId, postal_address, postalAddress, fa_key } = response.data.data;
                localStorage.setItem("token", token)

                const userObject = {
                    country,
                    dwelling_id,
                    email,
                    id,
                    name,
                    token: token,
                    family_name,
                    iat,
                    id_number,
                    identificationNumber,
                    language,
                    phone,
                    pilotId,
                    postal_address,
                    postalAddress,
                    fa_enabled: !!fa_key ? true : false
                }
                console.log(userObject);
                dispatch(setUserState(userObject))
                localStorage.setItem("user_logged_in", "true");

            }

            return response;
        } catch (err: any) {
            const status = err.response.status;
            if (status == 401 || status == 403) {
                localStorage.clear();
                sessionStorage.clear();
                window.location.href = "/";
            }
            console.log(err)
            return { data: { success: false, message: err.response.data.message } };
        }

    }

    return {
        getUserData
    }
}